<template>
    <div
        class="shadow-xs reply-item"
        :class="active ? 'toggle-on' : 'toggle-off'"
        data-inbox="message"
    >
        <!--begin::Message Heading-->
        <div
            class="d-flex card-spacer-x py-6 flex-column flex-md-row justify-content-between bg-hover-gray-100 mb-3 cursor-pointer"
            :class="{
                'customer-reply bg-gray-200': conversation.type === 'customer',
                'agent-reply': conversation.type !== 'customer',
            }"
            @click="active = !active"
        >
            <div class="d-flex align-items-center">
                <span
                    class="symbol symbol-50 mr-4"
                    :class="{
                        'symbol-warning': conversation.sendStatusData.is_bounce,
                        'symbol-danger': conversation.isSendStatusError,
                    }"
                >
                    <span
                        v-if="conversation.createdBy.photoUrl != ''"
                        class="symbol-label"
                        :style="{
                            backgroundImage: `url(${conversation.createdBy.photoUrl})`,
                        }"
                    ></span>
                    <span v-else class="symbol-label font-size-h5 font-weight-bold">
                        {{
                            conversation.createdBy.firstName != null
                                ? conversation.createdBy.firstName.slice(0, 1) +
                                  (conversation.createdBy.lastName != null
                                      ? conversation.createdBy.lastName.slice(0, 1)
                                      : "")
                                : conversation.createdBy.email.slice(0, 2).toUpperCase()
                        }}
                    </span>
                </span>
                <div class="d-flex flex-column flex-grow-1 flex-wrap mr-2">
                    <div class="d-flex">
                        <div
                            class="font-size-lg font-weight-bolder text-dark-75 text-hover-primary mr-2"
                        >
                            {{
                                conversation.createdBy.firstName != ""
                                    ? conversation.createdBy.firstName +
                                      " " +
                                      conversation.createdBy.lastName
                                    : conversation.createdBy.email
                            }}
                        </div>
                        <div
                            class="font-weight-bold text-warning"
                            v-if="conversation.sendStatusData.is_bounce"
                        >
                            <span class="label label-warning label-dot mr-2"></span>Acesta
                            este un mesaj de respingere
                        </div>
                        <div
                            class="font-weight-bold text-danger"
                            v-if="conversation.isSendStatusError"
                        >
                            <span class="label label-danger label-dot mr-2"></span>Acesta
                            mesaj nu a fost trimis
                        </div>
                        <div
                            class="font-weight-bold text-info"
                            v-if="conversation.scheduleAt"
                        >
                            <!-- <span class="label label-info label-dot mr-2"></span> -->
                            <b-icon icon="clock"></b-icon>
                            Conversatie programata la:
                            {{ conversation.scheduleAt | moment("DD-MM-YY HH:mm") }}
                        </div>
                    </div>
                    <div class="d-flex flex-column">
                        <div class="toggle-off-item">
                            <span
                                v-if="conversation.createdBy.type == 'customer'"
                                class="font-weight-bold text-muted cursor-pointer"
                                data-toggle="dropdown"
                                aria-expanded="false"
                                >client:
                                <span class="font-weight-bold text-primary">{{
                                    conversation.createdBy.email
                                }}</span>
                                <i class="flaticon2-down icon-xs ml-1 text-dark-50"></i
                            ></span>
                            <span
                                v-else
                                class="font-weight-bold text-muted cursor-pointer"
                                data-toggle="dropdown"
                                aria-expanded="false"
                                >agent
                                <i class="flaticon2-down icon-xs ml-1 text-dark-50"></i
                            ></span>
                        </div>
                        <div
                            class="text-muted font-weight-bold toggle-on-item"
                            data-inbox="toggle"
                        >
                            {{ conversation.preview }}
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="d-flex my-2 my-xxl-0 align-items-md-center align-items-lg-start align-items-xxl-center flex-column flex-md-row flex-lg-column flex-xxl-row flex-wrap text-right"
            >
                <div class="font-weight-bold mx-2">
                    {{ conversation.createdAt | moment("DD-MM-YY HH:mm") }}
                </div>
                <div
                    class="d-flex align-items-center flex-wrap flex-xxl-nowrap"
                    data-inbox="toolbar"
                >
                    <span
                        v-if="conversation.createdBy.type == 'customer'"
                        class="svg-icon svg-icon-md svg-icon-primary"
                    >
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <inline-svg
                                    v-bind="attrs"
                                    v-on="on"
                                    src="/media/svg/icons/Communication/Right.svg"
                                ></inline-svg>
                            </template>
                            <span>Mail primit</span>
                        </v-tooltip>
                    </span>
                    <span v-else class="svg-icon svg-icon-md svg-icon-success">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <inline-svg
                                    v-bind="attrs"
                                    v-on="on"
                                    src="/media/svg/icons/Communication/Forward.svg"
                                ></inline-svg>
                            </template>
                            <span>Mail trimis</span>
                        </v-tooltip>
                    </span>

                    <span class="text-info ml-3" v-if="getAttachments.length > 0">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon color="grey" dark v-bind="attrs" v-on="on">
                                    mdi-attachment
                                </v-icon>
                            </template>
                            <span>Mailul are atasamente</span>
                        </v-tooltip>
                    </span>
                </div>
            </div>
        </div>
        <!--end::Message Heading-->
        <!--begin::Message Error-->

        <div class="card-spacer-x py-3 toggle-off-item outline-danger">
            <v-alert
                border="left"
                color="red"
                dense
                elevation="7"
                outlined
                prominent
                type="error"
                v-if="conversation.isSendStatusError"
            >
                <span class="d-flex flex-row">Acest mesaj nu a putut fi trimis</span>
                <span v-if="conversation.sendStatusData" class="text-info">
                    {{ conversation.sendStatusData.msg }}
                </span>
                <span v-if="conversation.sendStatusData.bounced_by_conversation">
                    Raspunsul primit il puteti gasi in email-ul:
                    <router-link
                        :to="{
                            name: 'conversation',
                            params: { id: bounceMessage.id },
                        }"
                        class="text-primary"
                    >
                        (#{{ bounceMessage.number }})
                    </router-link>
                </span>
            </v-alert>
            <v-alert
                border="left"
                color="orange"
                dense
                elevation="2"
                outlined
                prominent
                type="warning"
                v-if="
                    !conversation.isSendStatusError &&
                    conversation.sendStatusData.is_bounce
                "
            >
                <span class="d-flex flex-row">Acesta este un mesaj de respingere</span>
                <span
                    v-if="
                        conversation.sendStatusData.bounce_for_conversation &&
                        bounceMessage
                    "
                >
                    Mesajul initial il puteti gasi in email-ul:
                    <router-link
                        :to="{
                            name: 'conversation',
                            params: { id: bounceMessage.id },
                        }"
                        class="text-primary"
                    >
                        (#{{ bounceMessage.number }})
                    </router-link>
                </span>
            </v-alert>
        </div>
        <!--end::Message Heading-->
        <!--begin::Message Error-->

        <div class="card-spacer-x py-3 toggle-off-item outline-danger">
            <v-alert
                border="left"
                color="red"
                dense
                elevation="7"
                outlined
                prominent
                type="error"
                v-if="conversation.isSendStatusError"
            >
                <span class="d-flex flex-row">Acest mesaj nu a putut fi trimis</span>
                <span v-if="conversation.sendStatusData.bounced_by_conversation">
                    Raspunsul primit il puteti gasi in email-ul:
                    <router-link
                        :to="{
                            name: 'conversation',
                            params: { id: bounceMessage.id },
                        }"
                        class="text-primary"
                    >
                        (#{{ bounceMessage.number }})
                    </router-link>
                </span>
            </v-alert>
            <v-alert
                border="left"
                color="orange"
                dense
                elevation="2"
                outlined
                prominent
                type="warning"
                v-if="
                    !conversation.isSendStatusError &&
                    conversation.sendStatusData.is_bounce
                "
            >
                <span class="d-flex flex-row">Acesta este un mesaj de respingere</span>
                <span
                    v-if="
                        conversation.sendStatusData.bounce_for_conversation &&
                        bounceMessage
                    "
                >
                    Mesajul initial il puteti gasi in email-ul:
                    <router-link
                        :to="{
                            name: 'conversation',
                            params: { id: bounceMessage.id },
                        }"
                        class="text-primary"
                    >
                        (#{{ bounceMessage.number }})
                    </router-link>
                </span>
            </v-alert>
        </div>

        <!--end::Message Error-->

        <div class="card-spacer-x py-3 toggle-off-item">
            <div class="px-8 mb-2 min-h-25px rounded border border-light">
                <div class="align-items-center inbox-to-cc pr-5 min-h-45px d-flex">
                    <div class="w-50px">Catre:</div>
                    <div class="flex-grow-1">
                        <span v-for="(email, idx) in conversation.to" :key="idx">{{
                            email + " "
                        }}</span>
                    </div>
                </div>

                <div
                    class="align-items-center border-bottom inbox-to-cc pr-5 min-h-45px d-flex"
                >
                    <div class="w-50px">Cc:</div>
                    <div class="flex-grow-1">
                        <span v-for="(email, idx) in conversation.cc" :key="idx">{{
                            email + " "
                        }}</span>
                    </div>
                </div>
                <!--begin::BCC-->
                <div
                    class="align-items-center border-bottom inbox-to-bcc pr-5 min-h-45px d-flex"
                >
                    <div class="w-50px">Bcc:</div>
                    <div class="flex-grow-1">
                        <span v-for="(email, idx) in conversation.bcc" :key="idx">{{
                            email + " "
                        }}</span>
                    </div>
                </div>
                <!--end::BCC-->
            </div>

            <b-card class="overflow-auto">
                <OriginalConversation :id="conversation.id" />

                <div class="replied-body h-100">
                    <p v-if="!hasStyle" v-html="conversation.body"></p>

                    <iframe
                        v-else
                        id="preview"
                        class="w-100"
                        frameborder="0"
                        allowtransparency="true"
                        style="width: 0; min-width: 100% !important"
                        :height="iframeHeight"
                    ></iframe>
                </div>

                <ForwardModal :conversation="conversation" />
            </b-card>

            <div v-html="final_forward"></div>
            <AttachmentList
                :attachments="getAttachments"
                :showRemove="false"
                @removeFile="removeFile"
            />
        </div>
    </div>
</template>

<script>
import AttachmentList from "./AttachmentList";
import OriginalConversation from "@/view/pages/conversation/buttons/OriginalConversation";
import ForwardModal from "@/view/pages/conversation/conversation-comp/ForwardModal";

export default {
    name: "MessageItem",
    props: ["conversation", "index"],
    data() {
        return {
            active: false,
            bounceMessage: false,
        };
    },
    components: { AttachmentList, OriginalConversation, ForwardModal },
    computed: {
        getAttachments() {
            return this.conversation._embedded.attachments;
        },
        iframeHeight() {
            return "300px";
        },
        hasStyle() {
            return this.conversation.body.includes("</style>");
        },
    },
    watch: {
        "$store.state.mails.conversation": function () {
            this.getBounce();
        },
    },
    methods: {
        getBounce() {
            var conv_id = null;
            if (this.conversation.sendStatusData.bounced_by_conversation) {
                conv_id = this.conversation.sendStatusData.bounced_by_conversation;
            } else if (this.conversation.sendStatusData.bounce_for_conversation) {
                conv_id = this.conversation.sendStatusData.bounce_for_conversation;
            }
            if (conv_id) {
                this.$store
                    .dispatch("mails/getBounceConversation", conv_id)
                    .then((response) => {
                        this.bounceMessage = response;
                    });
            }
        },
        resizeIFrameToFitContent(iFrame) {
            // iFrame.width = iFrame.contentWindow.document.body.scrollWidth + 'px';
            iFrame.style.height = iFrame.contentWindow.document.body.scrollHeight + "px";
        },
    },
    mounted() {
        this.getBounce();
        if (this.hasStyle) {
            let iframe = document.getElementById("preview");
            iframe.srcdoc = this.conversation.body;
        }
    },
};
</script>

<style scoped>
.replied-body img {
    max-width: 100%;
}
</style>
