<template>
    <b-modal :id="id" size="xl" title="Conversatii predefinite" @ok="handleOk">
        <v-data-table
            :headers="dessertHeaders"
            :items="savedReplies"
            :expanded.sync="expanded"
            item-key="name"
            :search="search"
            show-expand
            class="elevation-1"
        >
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>Lista conversatii</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Cauta"
                        single-line
                        hide-details
                    ></v-text-field>
                </v-toolbar>
            </template>
            <!-- <template v-slot:item.selectconv="{ item }">
        <v-radio :key="item.id" :value="item.id"></v-radio>
      </template> -->

            <template v-slot:item.actions="{ item }">
                <v-radio-group v-model="selected" name="saved-replies-radio1" dense>
                    <v-radio :value="item.id"></v-radio>
                </v-radio-group>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="p-2"><p v-html="item.text"></p></td>
            </template>
        </v-data-table>
    </b-modal>
</template>

<script>
export default {
    data() {
        return {
            selected: "",
            search: "",
            expanded: [],
            dessertHeaders: [
                {
                    text: "Name",
                    align: "start",
                    sortable: false,
                    value: "name",
                },
                { text: "Select", value: "actions", sortable: false, width: "5%" },
                { text: "", value: "data-table-expand" },
            ],
        };
    },
    props: ["id"],
    computed: {
        savedReplies() {
            return this.$store.getters["mails/savedReplies"];
        },
    },
    methods: {
        handleOk() {
            if (this.selected === "") {
                return;
            }
            const index = this.savedReplies
                .map((reply) => reply.id)
                .indexOf(this.selected);
            let paylod = {
                text: this.savedReplies[index].text,
            };
            this.$emit("insert", paylod);
        },
        // getSavedReplies() {
        //   let payload = {
        //     id: this.convId,
        //   };
        //   this.$store.dispatch("mails/getSavedReplies", payload);
        // },
    },
    mounted() {
        // console.log("mounted", this.id);
    },
    destroyed() {
        this.$store.commit("mails/setSavedReplies", []);
        // console.log("se distruge");
    },
};
</script>
