var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-chip',{attrs:{"x-small":"","close":_vm.status == 'open' ? true : false,"text-color":_vm.message == 'Clientul a raspuns'
            ? 'white'
            : _vm.message == 'Clientul nu a raspuns'
            ? 'white'
            : '#3699FF',"color":_vm.message == 'Clientul a raspuns'
            ? '#1bc5bd'
            : _vm.message == 'Clientul nu a raspuns'
            ? '#f64e60'
            : '#E1F0FF',"label":""},on:{"click:close":function($event){_vm.labelDialog = true}}},[_vm._v(" "+_vm._s(_vm.message)+" "),(_vm.status == 'open')?_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.labelDialog),callback:function ($$v) {_vm.labelDialog=$$v},expression:"labelDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Editare/Stergere eticheta")]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":""},model:{value:(_vm.labelStatus),callback:function ($$v) {_vm.labelStatus=$$v},expression:"labelStatus"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.labelDialog = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.updateConv(null)}}},[_vm._v("Sterge")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.updateConv(_vm.labelStatus)}}},[_vm._v("Modifica")]),_c('v-spacer')],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }