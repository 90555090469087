<template>
    <b-button-group class="mx-1 btn-group">
        <b-button
            :variant="!disabled ? `outline-${variant}` : ''"
            class="ml-1 spam-btn btn-secondary"
            @click="mailSpam"
            :disabled="disabled"
        >
            <span class="svg-icon" :class="`svg-icon-${variant}`">
                <inline-svg src="/media/svg/icons/Code/Warning-1-circle.svg"></inline-svg>
            </span>
            SPAM</b-button
        >
        <SpamDialog
            v-if="confirmSpam"
            :spamDialog="confirmSpam"
            :inConversation="inConversation"
            :conversation="conversation"
            @input="undoItem"
            @reload-conversation="reloadConversation"
        />
    </b-button-group>
</template>

<script>
import { mapActions } from "vuex";
import SpamDialog from "@/view/content/buttons/SpamDialog.vue";
export default {
    data() {
        return {
            confirmSpam: false,
        };
    },
    props: ["multiple", "conversation", "inConversation", "variant", "disabled"],
    computed: {},
    components: {
        SpamDialog,
    },
    methods: {
        ...mapActions("mails", ["selectMail"]),
        ...mapActions("mails", ["resetSelected"]),
        mailSpam() {
            if (this.inConversation) {
                this.resetSelected(false);
                this.selectMail(this.conversation.id);
            }
            this.confirmSpam = !this.confirmSpam;
            this.$emit("reload-conversation", true);
        },
        undoItem(item) {
            this.confirmSpam = item;
        },
        reloadConversation() {
            this.confirmSpam = false;
            this.$emit("reload-conversation", true);
        },
    },
};
</script>
