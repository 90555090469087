<template>
  <div class="card card-custom gutter-b">
    <!--begin::Header-->
    <MailHeaderListComponent :conversation="conversation"></MailHeaderListComponent>
    <!--end::Header-->
    <!--begin::Body-->
    <b-collapse id="replay-collapse" class="mt-2" v-model="show">
      <ReplayBox :conversation="conversation" @changeToggle="changeToggle" />
    </b-collapse>
    <!--end::Body-->
    <!--end::Advance Table Widget 2-->
    <div class="reply-wrapper">
      <MessageItem
        v-for="(conv, index) in conversation._embedded.threads.messages"
        :conversation="conv"
        :index="index"
        :key="index"
      ></MessageItem>
    </div>
  </div>
</template>

<script>
import MailHeaderListComponent from "@/view/pages/conversation/conversation-comp/HeaderList";
import MessageItem from "@/view/pages/conversation/conversation-comp/MessageItem";
import ReplayBox from "@/view/pages/conversation/conversation-comp/ReplayBox";

export default {
  name: "widget-2",
  props: ["conversation"],
  data() {
    return {
      show: false,
    };
  },
  components: {
    MailHeaderListComponent,
    MessageItem,
    ReplayBox,
  },
  computed: {},
  methods: {
    changeToggle(status) {
      this.show = status;
    },
  },
  mounted() {
    //console.log("conversation", this.conversation);
  },
};
</script>
