<template>
    <div class="messages">
        <!--begin::Message In-->
        <div
            class="d-flex flex-column align-items-start"
            v-if="message.createdBy.id !== user.id"
        >
            <div
                class="d-flex align-items-center mb-1 mt-3"
                v-if="prev == undefined || message.createdBy.id != prev.createdBy.id"
            >
                <span class="symbol symbol-40 mr-3">
                    <img
                        alt="Pic"
                        :src="message.createdBy.photoUrl"
                        v-if="
                            message.createdBy.photoUrl !=
                            'https://sharedmailapi.pefoc.ro/img/default-avatar.png'
                        "
                    />
                    <span v-else class="symbol-label font-size-h5 font-weight-bold">
                        {{
                            message.createdBy.firstName.charAt(0) +
                            message.createdBy.lastName.charAt(0)
                        }}
                    </span>
                </span>
                <div>
                    <a
                        href="#"
                        class="text-dark-75 text-hover-primary font-weight-bold font-size-h6 mr-3"
                        >{{ message.createdBy.firstName }}
                        {{ message.createdBy.lastName }}</a
                    >
                    <span class="text-muted font-size-sm">
                        {{ message.createdAt | moment("DD-MM-YY HH:mm") }}</span
                    >
                </div>
            </div>
            <div
                class="rounded p-3 mb-1 bg-light-success text-dark-50 font-weight-bold font-size-lg text-left max-w-400px"
                v-html="message.body"
            ></div>
        </div>
        <!--end::Message In-->
        <!--begin::Message Out-->
        <div class="d-flex flex-column align-items-end" v-else>
            <div
                class="d-flex align-items-center mb-1"
                v-if="prev == undefined || message.createdBy.id != prev.createdBy.id"
            >
                <div>
                    <span class="text-muted font-size-sm">
                        {{ message.createdAt | moment("DD-MM-YY HH:mm") }}</span
                    >
                    <a
                        href="#"
                        class="text-dark-75 text-hover-primary font-weight-bold font-size-h6 ml-3"
                        >You</a
                    >
                </div>
                <span class="symbol symbol-40 ml-3">
                    <img
                        alt="Pic"
                        :src="message.createdBy.photoUrl"
                        v-if="
                            message.createdBy.photoUrl !=
                            'https://sharedmailapi.pefoc.ro/img/default-avatar.png'
                        "
                    />
                    <span v-else class="symbol-label font-size-h5 font-weight-bold">
                        {{
                            message.createdBy.firstName.charAt(0) +
                            message.createdBy.lastName.charAt(0)
                        }}
                    </span>
                </span>
            </div>
            <div
                class="mb-1 rounded p-3 bg-light-primary text-dark-50 font-weight-bold font-size-lg text-right max-w-400px"
                v-html="message.body"
            ></div>
        </div>
        <!--end::Message Out-->
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    data() {
        return {};
    },

    props: ["message", "prev"],
    computed: {
        ...mapGetters(["user"]),
    },
    mounted() {},
    methods: {},
};
</script>

<style scoped>
.symbol-label img {
    width: 90%;
    border-radius: 0.42rem;
}
div .symbol.active .symbol-label.symbol-photo {
    background-color: #1bc5bd;
}
div .symbol.busy .symbol-label.symbol-photo {
    background-color: #f3c200;
}
div .symbol.vacation .symbol-label.symbol-photo {
    background-color: #f64e60;
}
</style>
