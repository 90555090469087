<template>
    <div>
        <b-button class="mr-3 btn-pending" v-b-modal.put-pending-modal
            >Pune in asteptare</b-button
        >
        <b-button
            v-if="conversation.mailboxId !== 13"
            class="mr-3 btn-close-email"
            @click="closeMailBtn"
            >Finalizeaza</b-button
        >
        <b-button
            v-if="conversation.mailboxId !== 13"
            class="mr-3"
            variant="danger"
            v-b-modal.anuleaza-modal
            >Anuleaza</b-button
        >
        <b-button class="mr-3" variant="primary" v-b-modal.call-modal id="call-button">
            <span class="svg-icon svg-icon-md svg-icon-white">
                <inline-svg
                    color="white"
                    src="/media/svg/icons/Communication/Outgoing-call.svg"
                ></inline-svg>
            </span>
        </b-button>
        <b-tooltip target="call-button" triggers="hover">Finalizare telefonică</b-tooltip>
        <b-button
            class="mr-3 btn-confirm"
            :class="{ active: conversation.state === 'confirmed' }"
            id="confirmed-button"
            ><b-form-checkbox v-model="state" @change="confirm" size="">{{
                conversation.state === "confirmed" ? "Deconfirma" : "Confirma"
            }}</b-form-checkbox>
        </b-button>
        <b-tooltip
            v-if="conversation.state === 'confirmed'"
            target="confirmed-button"
            triggers="hover"
        >
            {{ confirmPerson }}</b-tooltip
        >
        <!-- <b-button class="mr-3" variant="outline-primary">Button</b-button> -->
        <b-modal id="anuleaza-modal" title="Motiv anulare conversatie" hide-footer>
            <b-form @submit="cancelMail">
                <b-form-group id="input-group-1" label="Mesaj motiv:" label-for="input-1">
                    <b-form-input
                        id="input-1"
                        v-model="form.message"
                        type="text"
                        placeholder="Introdu mesaj motiv"
                        required
                    ></b-form-input>
                </b-form-group>
                <b-button-group class="mx-1">
                    <b-button type="submit" class="spam-btn">Anuleaza</b-button>
                </b-button-group>
            </b-form>
        </b-modal>
        <!-- modal call -->
        <b-modal id="call-modal" title="A raspuns clientul?" hide-footer>
            <b-form @submit="callClient">
                <b-form-group
                    id="input-group-1"
                    label="Alege o varianta de mai jos pentru a putea finaliza conversatia"
                    label-for="input-1"
                >
                    <b-button-group class="mx-1">
                        <b-button
                            @click="setCallModal('aRaspuns')"
                            :variant="
                                callAnswered == 'aRaspuns' ? 'success' : 'outline-success'
                            "
                            class="spam-btn pull-right"
                        >
                            A raspuns
                        </b-button>
                        <b-button
                            @click="setCallModal('nuARaspuns')"
                            :variant="
                                callAnswered == 'nuARaspuns' ? 'danger' : 'outline-danger'
                            "
                            class="spam-btn pull-right"
                        >
                            Nu a raspuns
                        </b-button>
                    </b-button-group>
                    <b-button-group class="mx-1 flex-end" v-if="callAnswered != false">
                        <b-button type="submit" variant="primary" class="spam-btn">
                            Finalizeaza
                        </b-button>
                    </b-button-group>
                </b-form-group>
            </b-form>
        </b-modal>
        <!-- end modal call -->
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
    name: "ProcessButtons",
    data() {
        return {
            isConfirmed: "",
            form: {
                message: "",
            },
            callAnswered: false,
        };
    },
    props: ["conversation"],
    methods: {
        modalOpen() {
            if (this.convModal === false) {
                this.openConvModal;
            }
        },
        closeMailBtn() {
            let payload = {
                conversations_ids: this.conversation.id,
                status: "closed",
            };
            this.$store.dispatch("buttons/closeMail", payload).then((response) => {
                // this.$store.dispatch("mails/getConversation", this.conversation.id);
                // this.$store.dispatch("mails/resetConversation");
                this.$emit("mailaction", true);
                this.$router.push("/");
            });
        },
        confirm() {
            let payload = {};
            if (this.conversation.state === "confirmed") {
                payload.state = "published";
            } else {
                payload.state = "confirmed";
            }
            this.$store.dispatch("mails/confirmMail", payload);
        },
        cancelMail(evt) {
            evt.preventDefault();
            let payload = {
                conversations_ids: this.conversation.id,
                status: "closed",
                thread_message: this.form.message,
            };
            this.$store.dispatch("buttons/closeMail", payload).then(() => {
                // console.log(this.conversation.id);
                this.$store.dispatch("mails/getConversation", this.conversation.id);
                this.$bvModal.hide("anuleaza-modal");
                this.$emit("mailaction", true);
            });
        },
        setCallModal(txt) {
            this.callAnswered = txt;
        },
        callClient(evt) {
            evt.preventDefault();
            let payload = {
                conversations_ids: this.conversation.id,
                status: "closed",
                thread_message: "Clientul nu a raspuns",
            };

            if (this.callAnswered == "aRaspuns") {
                payload.thread_message = "Clientul a raspuns";
            }

            let noteMessage =
                payload.thread_message +
                " (" +
                moment(new Date()).format("DD-MM-YY hh:mm") +
                ")";
            let notePayload = {};
            notePayload.mailData = {
                type: "note",
                text: noteMessage,
                user: this.user.id,
            };
            notePayload.id = this.conversation.id;

            this.$store.dispatch("mails/replyThread", notePayload).then(() => {
                this.note = "";

                this.$store.dispatch("buttons/closeMail", payload).then(() => {
                    this.$store.dispatch("mails/getConversation", this.conversation.id);
                    this.$bvModal.hide("call-modal");
                    this.$emit("mailaction", true);
                });
            });
        },
    },
    computed: {
        ...mapActions("buttons", ["openConvModal"]),
        ...mapGetters("buttons", ["convModal"]),
        ...mapGetters("mails", ["conversation"]),
        ...mapGetters(["user"]),
        state() {
            return this.conversation.state === "confirmed";
        },
        confirmPerson() {
            const actions = this.conversation._embedded.threads.lineitems;
            const stateActions = actions.filter(
                (actions) => actions.state === "confirmed"
            );
            let name = "";
            if (stateActions[0].createdBy.firstName) {
                name = stateActions[0].createdBy.firstName;
            }

            if (stateActions[0].createdBy.lastName) {
                name += " " + stateActions[0].createdBy.lastName;
            }
            return name;
        },
    },
    created() {
        // this.state = this.conversation.state;
    },
};
</script>

<style scoped>
.btn-pending {
    background-color: #1ba39c;
    color: white;
    opacity: 0.7;
}

.btn-pending:hover,
.btn-pending:focus {
    background-color: #1ba39c !important;
    opacity: 1;
}

.btn-close-email {
    background-color: #bbb;
    color: white;
}

.btn-confirm {
    background-color: #fff;
    border-color: #000;
}

.btn-confirm.active {
    background-color: #c49f47 !important;
    color: #fff !important;
}
</style>
