<template>
    <v-chip
        x-small
        :close="status == 'open' ? true : false"
        :text-color="
            message == 'Clientul a raspuns'
                ? 'white'
                : message == 'Clientul nu a raspuns'
                ? 'white'
                : '#3699FF'
        "
        :color="
            message == 'Clientul a raspuns'
                ? '#1bc5bd'
                : message == 'Clientul nu a raspuns'
                ? '#f64e60'
                : '#E1F0FF'
        "
        @click:close="labelDialog = true"
        label
    >
        {{ message }}
        <v-dialog v-model="labelDialog" max-width="500px" v-if="status == 'open'">
            <v-card>
                <v-card-title class="text-h5">Editare/Stergere eticheta</v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="labelStatus"
                                    label=""
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="labelDialog = false"
                        >Cancel</v-btn
                    >
                    <v-btn color="blue darken-1" text @click="updateConv(null)"
                        >Sterge</v-btn
                    >
                    <v-btn color="blue darken-1" text @click="updateConv(labelStatus)"
                        >Modifica</v-btn
                    >
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-chip>
</template>

<script>
export default {
    data() {
        return {
            labelDialog: false,
            labelStatus: "test",
        };
    },
    props: ["status", "id", "message"],
    watch: {
        message() {
            this.labelStatus = this.message;
        },
    },
    methods: {
        updateConv(value) {
            let payload = {
                conversations_ids: this.id,
                status: this.status,
                pending_custom: value,
                pending_date: new Date().toJSON().slice(0, 10),
            };

            if (value) {
                payload.pending_status = "custom";
            }

            this.$store.dispatch("buttons/updateStatus", payload).then((response) => {
                if (response) {
                    this.labelDialog = false;
                }
            });
        },
    },
    mounted() {
        this.labelStatus = this.message;
    },
};
</script>
