<template>
    <div class="card-spacer mb-3" id="kt_inbox_reply">
        <div class="card card-custom shadow-sm">
            <div class="card-body p-0">
                <!--begin::Form-->
                <b-form id="kt_inbox_reply_form" @submit="onSubmit" @reset="onReset">
                    <!--begin::Body-->
                    <div class="d-block">
                        <!--begin::To-->
                        <div
                            class="d-flex align-items-center border-bottom inbox-to px-8 min-h-50px"
                        >
                            <div class="text-dark-50 w-75px">To:</div>
                            <div class="d-flex align-items-center flex-grow-1">
                                <v-menu
                                    v-if="conversation.customer.firstName != null"
                                    v-model="menu"
                                    bottom
                                    right
                                    transition="scale-transition"
                                    origin="top left"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-chip pill v-on="on">
                                            <v-avatar left>
                                                <v-icon>mdi-account-circle</v-icon>
                                            </v-avatar>
                                            {{
                                                conversation.customer.firstName +
                                                " " +
                                                conversation.customer.lastName
                                            }}
                                        </v-chip>
                                    </template>
                                    <v-card width="300">
                                        <v-list light>
                                            <v-list-item>
                                                <v-list-item-avatar>
                                                    <v-img
                                                        src="media/users/100_11.jpg"
                                                    ></v-img>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title>{{
                                                        conversation.customer.firstName +
                                                        " " +
                                                        conversation.customer.lastName
                                                    }}</v-list-item-title>
                                                    <v-list-item-subtitle>{{
                                                        conversation.customer.email
                                                    }}</v-list-item-subtitle>
                                                </v-list-item-content>
                                                <v-list-item-action>
                                                    <v-btn icon @click="menu = false">
                                                        <v-icon>mdi-close-circle</v-icon>
                                                    </v-btn>
                                                </v-list-item-action>
                                            </v-list-item>
                                        </v-list>
                                    </v-card>
                                </v-menu>

                                <v-chip
                                    class="ma-2"
                                    color="light"
                                    text-color="black"
                                    v-else
                                >
                                    <v-avatar left>
                                        <v-icon>mdi-account-circle</v-icon>
                                    </v-avatar>
                                    {{ conversation.customer.email }}
                                </v-chip>

                                <input
                                    type="text"
                                    class="form-control border-0 d-none"
                                    name="compose_to"
                                    :value="conversation.customer.email"
                                />
                            </div>

                            <div class="ml-2">
                                <span
                                    @click="
                                        schedule.showScheduleConv = !schedule.showScheduleConv
                                    "
                                    class="fs-bold cursor-pointer text-hover-primary"
                                    :class="
                                        schedule.showScheduleConv
                                            ? 'text-primary'
                                            : 'text-muted'
                                    "
                                    >Conversatie programata</span
                                >
                            </div>
                        </div>
                        <div
                            class="align-items-center border-bottom inbox-to-cc pl-8 pr-5 min-h-45px d-flex"
                            :class="{ 'border-danger text-danger': errors.cc }"
                        >
                            <div
                                class="w-75px"
                                :class="errors.cc ? 'text-danger' : 'text-dark-50'"
                            >
                                Cc:
                            </div>
                            <div class="flex-grow-1">
                                <v-combobox
                                    v-model="cc"
                                    :items="items"
                                    :loading="isLoading"
                                    :search-input.sync="search"
                                    hide-no-data
                                    chips
                                    clearable
                                    hide-details
                                    hide-selected
                                    :item-text="getItemText"
                                    label="Introdu email Cc"
                                    flat
                                    multiple
                                    solo
                                    @focus="errors.cc = false"
                                >
                                    <template
                                        v-slot:selection="{ attr, on, item, selected }"
                                    >
                                        <v-chip
                                            close
                                            @click:close="remove(item.id, cc, item)"
                                            v-bind="attr"
                                            :input-value="selected"
                                            v-on="on"
                                        >
                                            <v-icon left> mdi-account-circle </v-icon>
                                            <span
                                                v-text="getItemEmail(item, 'cc')"
                                            ></span>
                                        </v-chip>
                                    </template>
                                    <template v-slot:item="{ item }">
                                        <v-list-item-avatar
                                            color="indigo"
                                            class="text-h5 font-weight-light white--text"
                                        >
                                            {{ getAvatar(item) }}
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title
                                                v-text="
                                                    (item.firstName
                                                        ? item.firstName
                                                        : '') +
                                                    ' ' +
                                                    (item.lastName ? item.lastName : '')
                                                "
                                            ></v-list-item-title>
                                            <v-list-item-subtitle
                                                v-text="item._embedded.emails[0].value"
                                            ></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>
                                </v-combobox>
                            </div>
                        </div>
                        <!--begin::BCC-->
                        <div
                            class="align-items-center border-bottom inbox-to-bcc pl-8 pr-5 min-h-45px d-flex"
                            :class="{ 'border-danger text-danger': errors.bcc }"
                        >
                            <div
                                class="w-75px"
                                :class="errors.bcc ? 'text-danger' : 'text-dark-50'"
                            >
                                Bcc:
                            </div>
                            <div class="flex-grow-1">
                                <v-combobox
                                    v-model="bcc"
                                    :items="items"
                                    :loading="isLoading"
                                    :search-input.sync="search"
                                    hide-no-data
                                    chips
                                    clearable
                                    hide-details
                                    hide-selected
                                    :item-text="getItemText"
                                    label="Introdu mail Bcc..."
                                    flat
                                    multiple
                                    solo
                                    @focus="errors.bcc = false"
                                >
                                    <template
                                        v-slot:selection="{ attr, on, item, selected }"
                                    >
                                        <v-chip
                                            close
                                            @click:close="remove(item.id, bcc, item)"
                                            v-bind="attr"
                                            :input-value="selected"
                                            v-on="on"
                                        >
                                            <v-icon left> mdi-account-circle </v-icon>
                                            <span
                                                v-text="getItemEmail(item, 'bcc')"
                                            ></span>
                                        </v-chip>
                                    </template>
                                    <template v-slot:item="{ item }">
                                        <v-list-item-avatar
                                            color="indigo"
                                            class="text-h5 font-weight-light white--text"
                                        >
                                            {{ getAvatar(item) }}
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title
                                                v-text="
                                                    (item.firstName
                                                        ? item.firstName
                                                        : '') +
                                                    ' ' +
                                                    (item.lastName ? item.lastName : '')
                                                "
                                            ></v-list-item-title>
                                            <v-list-item-subtitle
                                                v-text="item._embedded.emails[0].value"
                                            ></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>
                                </v-combobox>
                            </div>
                        </div>
                        <!--end::BCC-->
                        <DateTimePicker v-model="schedule" />
                        <!--end::To-->
                        <!--begin::Message-->

                        <div
                            id="kt_inbox_reply_editor"
                            class="border-0 ql-container ql-snow"
                            style="min-height: 250px"
                        >
                            <vue-editor
                                v-model="content"
                                :editor-toolbar="customToolbar"
                            />
                        </div>

                        <!--end::Message-->
                    </div>
                    <!--end::Body-->
                    <AttachmentZone
                        :attachments="getAttachments"
                        :dropzoneShow="getDropzoneShow"
                        @setAttachments="setAttachments"
                    />

                    <SavedReply
                        @insert="setContent"
                        :id="'saved-replies-existing-conv'"
                    />
                    <!--begin::Footer-->
                    <div
                        class="d-flex align-items-center justify-content-between py-5 pl-8 pr-5 border-top"
                    >
                        <!--begin::Actions-->
                        <div class="d-flex align-items-center mr-3">
                            <!--begin::Send-->
                            <div class="btn-group mr-4">
                                <span
                                    class="btn btn-primary font-weight-bold px-6"
                                    @click="onSubmit"
                                    >Trimite<span
                                        class="svg-icon svg-icon-md svg-icon-white ml-3"
                                    >
                                        <inline-svg
                                            src="/media/svg/icons/Communication/Send.svg"
                                        ></inline-svg> </span
                                ></span>
                            </div>
                            <!--end::Send-->
                            <!--begin::Other-->
                            <span
                                class="btn btn-icon btn-sm btn-clean mr-2"
                                id="kt_inbox_reply_attachments_select"
                                @click="dropzoneShow = !dropzoneShow"
                            >
                                <i class="flaticon2-clip-symbol"></i>
                            </span>

                            <span
                                class="btn btn-icon btn-sm btn-clean mr-2"
                                id="kt_inbox_reply_saved"
                                @click="openSavedReplies"
                            >
                                <i class="flaticon2-medical-records"></i>
                            </span>
                            <!--end::Other-->
                        </div>
                        <!--end::Actions-->
                        <!--begin::Toolbar-->
                        <div class="d-flex align-items-center">
                            <span class="btn btn-icon btn-sm btn-clean" @click="onReset">
                                <i class="flaticon2-refresh-arrow"></i>
                            </span>
                            <span
                                class="btn btn-icon btn-sm btn-clean"
                                v-b-toggle.replay-collapse
                            >
                                <i class="flaticon2-rubbish-bin-delete-button"></i>
                            </span>
                        </div>
                        <!--end::Toolbar-->
                    </div>
                    <!--end::Footer-->
                </b-form>
                <!--end::Form-->
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { VueEditor } from "vue2-editor";
import AttachmentZone from "./AttachmentZone";
import DateTimePicker from "@/view/content/DateTimePicker";
import SavedReply from "@/view/pages/conversation/SavedReply";
import moment from "moment";
import VueCkeditor from "vue-ckeditor2";
// import { VueEditor, Quill } from "vue2-editor";

export default {
    name: "ReplayBox",
    props: ["conversation"],

    data: () => ({
        content: "Buna ziua,<br/><br/>",
        customToolbar: [
            [{ size: ["small", false, "large", "huge"] }],
            ["bold", "italic", "underline"],
            [{ color: [] }, { background: [] }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            ["link"],
            ["clean"],
        ],
        dropzoneShow: false,
        attachments: [],
        schedule: {
            date: "",
            time: "",
            showScheduleConv: false,
        },
        savedReply: {
            isOpen: false,
        },
        cc: [],
        bcc: [],
        errors: {
            cc: false,
            bcc: false,
        },
        items: [],
        isLoading: false,
        search: null,
    }),
    components: { VueEditor, AttachmentZone, DateTimePicker, SavedReply },
    watch: {
        search(val) {
            // Items have already been loaded
            if (val === "") return;

            this.isLoading = true;
            // Lazily load input items

            this.$store
                .dispatch("mails/searchCustomers", val)
                .then((res) => {
                    this.items = res;
                })
                .finally(() => (this.isLoading = false));
        },
    },
    computed: {
        getAttachments() {
            return this.attachments;
        },
        getDropzoneShow() {
            return this.dropzoneShow;
        },
        ...mapGetters(["user"]),
    },
    mounted() {
        this.conversation.cc.map((elem) => {
            this.cc.push(elem);
        });
        this.conversation.bcc.map((elem) => {
            this.bcc.push(elem);
        });
        // console.log(this.conversation);
    },
    methods: {
        onSubmit(evt) {
            evt.preventDefault();

            let cc = [];
            this.cc.map((elem) => {
                const email = this.getItemEmail(elem, "cc");
                if (this.validateEmail(email) === null) {
                    this.errors.cc = true;
                }
                cc.push(email);
            });

            let bcc = [];
            this.bcc.map((elem) => {
                const email = this.getItemEmail(elem, "bcc");

                if (this.validateEmail(email) === null) {
                    this.errors.bcc = true;
                }
                bcc.push(email);
            });

            let payload = {};
            payload.mailData = {
                type: "message",
                status: this.conversation.status,
                text: this.content,
                user: this.user.id,
                attachments: this.attachments,
                cc: cc,
                bcc: bcc,
            };
            payload.id = this.conversation.id;

            if (this.schedule.time === "") {
                if (moment(this.schedule.date).isSame(moment(), "day")) {
                    this.schedule.time = moment().format("HH:mm:ss");
                } else {
                    this.schedule.time = "07:00:00";
                }
            }

            let datetimepicker = this.schedule.date + " " + this.schedule.time;

            if (datetimepicker) {
                payload.mailData.sl_schedule_date = datetimepicker;
            }
            if (!this.errors.bcc && !this.errors.cc) {
                this.$store.dispatch("mails/replyThread", payload).then((response) => {
                    this.$store.commit("alerts/setNewAlert", response);
                    this.$emit("changeToggle", false);
                    this.content = "";
                    // clear attachments zone
                    this.attachments = [];
                });
            }
        },
        setAttachments(attachments) {
            this.attachments = attachments;
        },
        onReset(evt) {
            evt.preventDefault();
            // Reset our form values
            this.content = "Buna ziua,</h3><br/><br/>";
            this.attachments = [];
            this.schedule.schedule_date = "";
            this.schedule.schedule_time = "";
            this.schedule.showScheduleConv = false;
        },
        setContent(payload) {
            this.content = payload.text;
        },
        openSavedReplies() {
            let payload = {
                id: this.conversation.mailboxId,
            };
            this.$store.dispatch("mails/getSavedReplies", payload).then(() => {
                this.$bvModal.show("saved-replies-existing-conv");
            });
        },
        getItemText(item) {
            return `${item.firstName} ${item.lastName} ${item._embedded.emails[0].value}`;
        },
        remove(id, from, item = null) {
            let idx = -1;

            if (id) {
                idx = from
                    .map((email) => {
                        return email.id;
                    })
                    .indexOf(id);
            }

            if (id === undefined) {
                if (!item) return;
                idx = from.indexOf(item);
            }
            from.splice(idx, 1);
            from = [...from];
        },
        getItemEmail(item, type = "to") {
            let email = false;
            if (item) {
                if (item._embedded) {
                    email = item._embedded.emails[0].value;
                } else {
                    email = item;

                    if (item.value !== "" || !item.value) {
                        let splitEmail = item.split(", ");
                        if (splitEmail.length > 1) {
                            splitEmail.forEach((dep) => {
                                if (type == "to") {
                                    this[type] = dep;
                                } else if (dep !== "") {
                                    this[type].push(dep);
                                }
                            });

                            if (type !== "to") {
                                this[type].splice(this[type].indexOf(item), 1);
                            }
                        }
                    }
                }
            }
            // if (this.validateEmail(email)) {
            //     this.form.errors[type] = false;
            // } else {
            //     this.form.errors[type] = true;
            // }
            return email;
        },
        getAvatar(item) {
            if (item.photoUrl) return photoUrl;

            if (item.firstName && item.lastName) {
                return (item.firstName.charAt(0) + item.lastName.charAt(0)).toUpperCase();
            }

            return item._embedded.emails[0].value.charAt(0).toUpperCase();
        },
        validateEmail(email) {
            return String(email)
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
        },
    },
};
</script>
