<template>
    <b-button
        class="ml-1 btn-success float-right"
        v-b-modal.callid-modal
        id="callid-button"
    >
        <span class="svg-icon svg-icon-md svg-icon-white mr-0">
            <inline-svg
                color="white"
                src="/media/svg/icons/Communication/Outgoing-call.svg"
            ></inline-svg>
        </span>
        <b-tooltip target="callid-button" triggers="hover">Apeleaza clientul</b-tooltip>
        <b-modal id="callid-modal" title="Apeleaza client" hide-footer>
            <b-form @submit="callClient">
                <b-form-group
                    id="input-group-1"
                    label="In cazul in care nu este numar gasit in continut, introduceti numar!"
                    label-for="input-1"
                >
                    <b-form-input
                        id="input-number"
                        v-model="mobileNumber"
                        :state="isNumberStateValid"
                        type="text"
                        placeholder="Introdu numar de telefon"
                        required
                    ></b-form-input>
                    <!-- This will only be shown if the preceding input has an invalid state -->
                    <b-form-invalid-feedback id="input-number">
                        Introdu un numar valid
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-button-group class="mx-1 flex-end">
                    <b-button type="submit" variant="primary" class="spam-btn">
                        <a title="Apeleaza"
                            ><i class="fa fa-phone"></i>Apeleaza prin 3CX
                        </a>
                    </b-button>
                </b-button-group>
            </b-form>
        </b-modal>
    </b-button>
</template>

<script>
import { GET_WORKFLOWS } from "@/core/services/store/modules/workflow";
import { DELETE_WORKFLOW } from "@/core/services/store/modules/workflow";

export default {
    data() {
        return {
            mobileNumber: null,
        };
    },
    props: ["conversation"],
    computed: {
        isNumberStateValid() {
            if (this.mobileNumber) {
                return this.isValidNumber(this.mobileNumber);
            }
            return null;
        },
    },
    methods: {
        isValidNumber() {
            var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
            return re.test(this.mobileNumber) ? true : false;
        },
        callClient(evt) {
            evt.preventDefault();
            if (this.isValidNumber()) {
                window.open("tel: " + this.mobileNumber, "_blank");
                this.$bvModal.hide("callid-modal");
            }
        },
    },
    mounted() {
        this.conversation._embedded.threads.messages.map((elem) => {
            let regex = /Caller\sID\s(\d\d\d\d\d\d\d\d\d\d)/i;
            const found = elem.body.match(regex);
            if (found) {
                this.mobileNumber = found[1];
            }
        });
    },
};
</script>
