<template>
  <!--begin::Profile 4-->
  <div class="card-header border-0 py-5">
    <h3 class="card-title align-items-start flex-column">
      <span class="card-label font-weight-bolder text-dark"
        ><span class="text-muted mr-1">Subiect:</span>{{ conversation.subject }}
        <Tag :tag="conversation._embedded.tags" :id="conversation.id" :size="'fa-lg'" />
      </span>
      <span class="text-muted mt-3 font-weight-bold font-size-sm"
        >Conversatie inceputa de:
        <span class="font-weight-bolder text-dark-75">{{
          conversation.createdBy.type == "customer" ? "Client" : "Agent"
        }}</span></span
      >
    </h3>
    <div class="card-toolbar" v-if="conversation.status === 'open'">
      <v-btn
        @click="follow"
        :color="conversation.is_following ? 'accent' : ''"
        depressed
        class="mr-2"
        height="38px"
        >{{ conversation.is_following ? "Urmaresti" : "Urmareste" }}</v-btn
      >
      <b-button
        v-b-toggle.replay-collapse
        variant="primary"
        class="btn btn-primary font-weight-bolder font-size-sm"
      >
        <span class="svg-icon svg-icon-md svg-icon-white">
          <inline-svg src="/media/svg/icons/Communication/Send.svg"></inline-svg> </span
        >Raspunde</b-button
      >
    </div>
  </div>
  <!--end::Profile 4-->
</template>

<script>
import { mapGetters } from "vuex";
import Tag from "@/view/pages/dashboard/Tag.vue";

export default {
  name: "MailHeaderListComponent",
  props: ["conversation"],
  data() {
    return {};
  },
  components: {
    Tag,
  },
  computed: {
    ...mapGetters(["currentUserPhoto"]),
  },
  methods: {
    follow() {
      let payload = {
        conversations_ids: [this.conversation.id],
        action: this.conversation.is_following ? "unfollow" : "follow",
      };
      this.$store.dispatch("mails/followConversation", payload);
    },
  },
  created() {
    // console.log(this.conversation);
    // this.$store.dispatch("mails/getConversation", this.urlParam);
  },
  mounted() {
    // console.log(this.configs);
  },
};
</script>
