<template>
  <b-button variant="outline-success" class="ml-1 spam-btn" @click="undoItem()">
    <v-icon small> mdi-undo </v-icon>
    INBOX
    <UnSpamDialog v-model="dialog" :conversation="conversation" />
  </b-button>
</template>

<script>
import UnSpamDialog from "@/view/content/buttons/UnSpamDialog";

export default {
  data() {
    return {
      dialog: false,
    };
  },
  props: ["conversation"],
  methods: {
    undoItem(item) {
      this.dialog = true;
    },
  },
  components: {
    UnSpamDialog,
  },
};
</script>
