<template>
    <div>
        <EditRuleDialog
            :showItem="showItem"
            :dialog="dialog"
            @close-dialog="closeDialog"
            @workflow-deleted="workflowDeleted"
            id="EditRuleDialog-AddRuleButton"
        />
        <b-button v-b-modal.rule-modal
            >Adauga regula
            <b-modal v-if="conversation.id" id="rule-modal">
                <template #modal-title> Adaugare regula </template>
                <!--begin::Form-->
                <!-- <b-form id="rule-form" @submit="onSubmit" @reset="onReset"> -->
                <v-form ref="ruleForm" v-model="valid" lazy-validation hide-footer>
                    <!--begin::Body-->
                    <v-row class="v-application">
                        <v-col cols="12">
                            <p class="font-weight-bold">
                                Continut regula (pe adresa de email)
                            </p>
                            <v-text-field
                                v-model="email"
                                :rules="emailRules"
                                solo
                                label="Adresa de email"
                                required
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12">
                            <p class="font-weight-bold">Conditii</p>
                            <b-form-group v-slot="{ ariaDescribedby }">
                                <b-form-radio-group
                                    id="btn-radios-2"
                                    v-model="operator"
                                    :options="options"
                                    :aria-describedby="ariaDescribedby"
                                    button-variant="outline-primary"
                                    name="radio-btn-outline"
                                    buttons
                                ></b-form-radio-group>
                            </b-form-group>
                        </v-col>

                        <v-col cols="12">
                            <p class="font-weight-bold">Actiuni</p>
                            <div class="v-application">
                                <v-select
                                    v-model="departId"
                                    :items="eachDepartments"
                                    @change="getPasareDeparts()"
                                    dense
                                    outlined
                                    :rules="[(v) => !!v || 'Campul este obligatoriu']"
                                    required
                                ></v-select>
                            </div>
                        </v-col>

                        <v-col v-if="showSelectUser">
                            <v-select
                                v-model="userId"
                                :items="departmentUsers"
                                dense
                                outlined
                                :rules="userRules"
                                required
                            >
                            </v-select>
                        </v-col>
                        <v-col cols="12">
                            <v-checkbox
                                v-model="applyPrevious"
                                hide-details
                                label="Aplica pe toate conversațiile primite anterior"
                            >
                                <template v-slot:label>
                                    <div class="mt-1 ml-1">
                                        Aplica pe toate conversațiile primite anterior
                                    </div>
                                </template>
                            </v-checkbox>
                        </v-col>

                        <v-col cols="12">
                            <v-checkbox
                                v-model="applyAllDepart"
                                hide-details
                                label="Aplica pe toate conversațiile primite anterior"
                            >
                                <template v-slot:label>
                                    <div class="mt-1 ml-1">
                                        Aplica pentru toate departamentele
                                    </div>
                                </template>
                            </v-checkbox>
                        </v-col>

                        <!--end::Body-->
                    </v-row>
                    <v-row v-if="errorString">
                        <v-col class="text-danger" v-if="errorType == 'error'">
                            Eroare: {{ errorString }}
                        </v-col>
                        <v-col class="text-info" v-if="errorType == 'info'">
                            {{ errorString }}
                        </v-col>
                    </v-row>
                    <v-row v-if="errorString">
                        <v-col class="text-right">
                            <DeleteAllWorkflowsButton 
                                v-if="workflow_exists.length > 1"
                                :workflow_exists="workflow_exists"
                                @workflow-deleted="workflowDeleted"
                                path="addRuleButton" />
                        </v-col>
                    </v-row>
                    <v-row v-if="workflow_exists.length > 0">
                        <v-col
                            v-for="(item, i) in workflow_exists"
                            v-bind:key="i"
                            class="text-black"
                        >
                            Departament: <b> {{ item.mailbox }} </b> <br />
                            {{ item.name }}
                            <template v-if="item.active">
                                <v-chip
                                    @click="showWorkflow(item)"
                                    success
                                    x-small
                                    color="green"
                                >
                                    vezi regula
                                </v-chip>
                            </template>
                        </v-col>
                    </v-row>
                </v-form>
                <template #modal-footer>
                    <b-button
                        type="submit"
                        form="ruleForm"
                        size="sm"
                        variant="primary"
                        @click="addRule"
                    >
                        Adauga regula
                    </b-button>
                </template>
                <!-- </b-form> -->
                <!--end::Form-->
            </b-modal>
        </b-button>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { GET_WORKFLOWS } from "@/core/services/store/modules/workflow";
import { EMAIL_WORKFLOW } from "@/core/services/store/modules/workflow";
import EditRuleDialog from "@/view/pages/workflows/EditRuleDialog.vue";
import DeleteAllWorkflowsButton from "@/view/content/buttons/DeleteAllWorkflowsButton.vue";

export default {
    data() {
        return {
            showSelectUser: false,
            departId: null,
            departName: null,
            userId: null,
            userName: null,
            email: "",
            emailRules: [(v) => !!v || "Campul este obligatoriu"],
            operator: "equal",
            options: [
                { text: "Incepe cu", value: "starts" },
                { text: "Se termina cu", value: "ends" },
                { text: "Exact", value: "equal" },
                { text: "Contine", value: "contains" },
            ],
            errorString: "",
            errorType: "error",
            workflow_exists: [],
            applyPrevious: true,
            applyAllDepart: false,
            showItem: {},
            dialog: false,
        };
    },
    components: {
        EditRuleDialog,
        DeleteAllWorkflowsButton,
    },
    watch: {
        departId() {
            this.$store
                .dispatch("departments/getDepartmentName", this.departId)
                .then((response) => {
                    this.departName = response;
                });
        },
        userId() {
            this.$store
                .dispatch("departments/getAgentName", this.userId)
                .then((response) => {
                    this.userName = response;
                });
        },
    },
    computed: {
        ...mapGetters("departments", ["eachDepartments"]),
        ...mapGetters("departments", ["departmentUsers"]),
        // ...mapGetters("mails", ["conversation"]),
        conversation() {
            return this.$store.getters["mails/conversation"];
        },
        userRules() {
            const rules = [];
            if (this.departId === this.conversation.mailboxId) {
                const rule = (v) =>
                    !!v ||
                    "Pentru departamentul curent, trebuie sa selectezi si agent pt ca regula sa fie valida";
                rules.push(rule);
            }

            return rules;
        },
    },
    methods: {
        getPasareDeparts() {
            let departId = this.departId;
            let userId = null;
            if (departId) {
                this.showSelectUser = true;
            }
            this.$store.dispatch("mails/asigneeDepUser", { departId, userId });
            this.$store.dispatch("departments/getPasareUsers", departId);
        },
        getDepartUsers() {
            let departId = this.departId;
            if (departId) {
                this.showSelectUser = true;
            }
            let userId = this.userId;
            this.$store.dispatch("mails/asigneeDepUser", { departId, userId });
        },
        addRule(evt) {
            evt.preventDefault();
            if (this.$refs.ruleForm.validate()) {
                this.errorString = "";
                let fromMailBoxes = [];
                let data = {
                    email: this.email,
                    operator: this.operator,
                    fromDepartId: this.conversation.mailboxId,
                    departId: this.departId,
                    departName: this.departName,
                    userId: this.userId,
                    userName: this.userName,
                    apply_to_prev: this.applyPrevious,
                };

                if (this.applyAllDepart) {
                    this.eachDepartments.map((depart) => {
                        let isSameDepartValid = true;
                        if (depart.value === this.departId && this.userId === null) {
                            isSameDepartValid = false;
                        }
                        if (depart.value && isSameDepartValid)
                            fromMailBoxes.push(depart.value);
                    });
                }
                // else {
                //     fromMailBoxes.push(this.conversation.mailboxId);
                // }

                if (fromMailBoxes.length) {
                    data.mailboxes_ids = fromMailBoxes;
                }

                this.$store.dispatch(EMAIL_WORKFLOW, data).then((response) => {
                    if (response.errors) {
                        this.errorType = "error";
                        if (response.errors && response.errors.workflow_exists) {
                            this.workflow_exists = response.errors.workflow_exists;
                            this.errorString =
                                "Regula exista pe urmatoarele departamente. Mai intai trebuie stearsa!";
                        } else {
                            for (const property in response.errors) {
                                this.errorString +=
                                    response.message + ". " + response.errors[property];
                            }
                        }
                    } else {
                        this.$bvModal.hide("rule-modal");
                        this.errorString = "";
                        this.departId = null;
                        this.userId = null;
                        // this.email = "";
                        this.operator = "equal";
                        this.$store.commit("alerts/setNewAlert", {
                            message_header: "Adaugare regula",
                            message_content: "Regula: a fost adaugata cu succes",
                            variant: "success",
                        });
                    }
                });
            }
        },
        showWorkflow(item) {
            this.showItem = item;
            this.dialog = true;
        },
        closeDialog() {
            this.dialog = false;
        },
        workflowDeleted(rule) {
            if (Array.isArray(rule)) {
                rule.forEach((val) => {
                    const i = this.workflow_exists.map((item) => item).indexOf(val);
                    this.workflow_exists.splice(i, 1);
                })
            }
            const i = this.workflow_exists.map((item) => item).indexOf(rule);
            this.workflow_exists.splice(i, 1);
            if (this.workflow_exists.length == 0) {
                this.errorString =
                    "Toate regulile au fost sterse. Acum puteti adauga regula noua!";
                this.errorType = "info";
            }
            this.dialog = false;
        },
    },
    mounted() {
        this.email = this.conversation.customer.email;
    },
};
</script>
