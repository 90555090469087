<template>
    <div v-if="logsActions" v-b-modal.logs-actions>
        <div class="d-flex align-items-center">
            <div class="symbol symbol-30 mr-3">
                <span class="symbol-label">
                    <span
                        class="svg-icon svg-icon-xl"
                        :class="
                            getColorSVG(
                                conversation._embedded.threads.lineitems[0].action.type
                            )
                        "
                    >
                        <!--begin::Svg Icon -->
                        <inline-svg
                            v-if="conversation._embedded.threads.lineitems[0].action.type"
                            :src="
                                getTypeSVG(
                                    conversation._embedded.threads.lineitems[0].action
                                        .type
                                )
                            "
                        />
                        <inline-svg v-else :src="getTypeSVG('default')" />
                        <!--end::Svg Icon-->
                    </span>
                </span>
            </div>
            <div class="navi-text">
                <div class="font-size-sm">
                    {{ conversation._embedded.threads.lineitems[0].action.text }}
                </div>
                <div class="text-muted">
                    {{
                        conversation._embedded.threads.lineitems[0].createdAt
                            | moment("DD-MM-YY HH:mm")
                    }}
                </div>
            </div>
        </div>

        <b-modal id="logs-actions" hide-footer>
            <template #modal-title> Istoric actiuni </template>

            <div
                v-for="action in logsActions"
                :key="'action_' + action.id"
                class="d-flex align-items-center mb-3"
            >
                <div class="symbol symbol-40 mr-3">
                    <span class="symbol-label">
                        <span
                            class="svg-icon svg-icon-xl"
                            :class="getColorSVG(action.action.type)"
                        >
                            <!--begin::Svg Icon -->
                            <inline-svg
                                v-if="action.action.type"
                                :src="getTypeSVG(action.action.type)"
                            />
                            <inline-svg v-else :src="getTypeSVG('default')" />
                            <!--end::Svg Icon-->
                        </span>
                    </span>
                </div>
                <div class="navi-text">
                    <div class="font-size-lg">
                        {{ action.action.text }}
                    </div>
                    <div class="text-muted">
                        {{ action.createdAt | moment("DD-MM-YY HH:mm") }}
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
    <div v-else>Deocamdata nici o actiune!</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    props: ["conversation"],
    computed: {
        logsActions() {
            return this.conversation._embedded.threads.lineitems;
        },
        ...mapGetters(["layoutConfig"]),
    },
    methods: {
        getTypeSVG(type) {
            return this.layoutConfig("actions." + type);
        },
        getColorSVG(type) {
            if (type) {
                return this.layoutConfig("actionsColor." + type);
            } else {
                return this.layoutConfig("actionsColor.default");
            }
        },
    },
};
</script>
