<template>
  <!-- begin::schedule -->
  <div
    v-if="value.showScheduleConv"
    class="align-items-center border-bottom pl-8 pr-5 min-h-45px d-flex"
  >
    <!-- <div class="w-75px">Data:</div> -->
    <div class="flex-grow-1">
      <b-form-datepicker
        id="datepicker-after"
        v-model="value.date"
        :min="new Date()"
        locale="ro"
        @input="changeDate"
        label-no-date-selected="Nici o data selectata"
      ></b-form-datepicker>
    </div>
    <div class="flex-grow-1">
      <b-form-timepicker
        v-model="value.time"
        locale="ro"
        @input="changeTime"
        close-button-variant
        label-close-button="Inchide"
        now-button
        label-now-button="Acum"
        :disabled="value.date === ''"
        label-no-time-selected="Nimic selectat"
      ></b-form-timepicker>
    </div>
    <span @click="value.showScheduleConv = false" class="btn btn-clean btn-xs btn-icon">
      <i class="la la-close"></i>
    </span>
  </div>
  <!--- end::schedule -->
</template>

<script>
import moment from "moment";

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  watch: {
    value() {
      this.$emit("input", this.value);
    },
  },
  methods: {
    changeDate() {
      this.isValidTime();
    },
    changeTime() {
      this.isValidTime();
    },
    isValidTime() {
      var currentTine = moment(moment(), "h:m");
      var selectedTime = moment(this.value.schedule_time, "h:m");
      if (
        moment(this.value.schedule_date).isSame(moment(), "day") &&
        selectedTime.isBefore(currentTine)
      ) {
        this.value.schedule_time = moment().format("HH:mm:ss");
      }
    },
  },
};
</script>
