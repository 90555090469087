<template>
    <b-button
        class="ml-1 btn-primary float-right"
        v-b-modal.orderlist-modal
        id="orderlist-button"
        variant="secondary"
    >
        <span class="svg-icon svg-icon-md svg-icon-blue mr-0">
            <i class="far fa-list-alt"></i>
        </span>
        <b-tooltip target="orderlist-button" triggers="hover">Cauta comenzi</b-tooltip>
        <b-modal id="orderlist-modal" title="Cauta comenzi" hide-footer>
            <b-form @submit="checkList">
                <b-form-group
                    id="input-group-1"
                    label="In cazul in care nu este numar gasit in continut, introduceti numar!"
                    label-for="input-1"
                >
                    <b-form-input
                        id="input-number"
                        v-model="mobileNumber"
                        :state="isNumberStateValid"
                        type="text"
                        placeholder="Introdu numar de telefon"
                        required
                    ></b-form-input>
                    <!-- This will only be shown if the preceding input has an invalid state -->
                    <b-form-invalid-feedback id="input-number">
                        Introdu un numar valid
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-button-group class="mx-1 flex-end">
                    <b-button type="submit" variant="primary" class="spam-btn">
                        <a title="Apeleaza" @click="openOrderList"
                            ><i class="far fa-list-alt"></i>Verifica lista comenzi
                        </a>
                    </b-button>
                </b-button-group>
            </b-form>
        </b-modal>

        <OrderListModal id="orderlist" :orders="orders" />
    </b-button>
</template>

<script>
import { GET_WORKFLOWS } from "@/core/services/store/modules/workflow";
import { DELETE_WORKFLOW } from "@/core/services/store/modules/workflow";
import OrderListModal from "@/view/pages/conversation/OrderListModal";

export default {
    data() {
        return {
            mobileNumber: null,
        };
    },
    props: ["conversation"],
    components: {
        OrderListModal,
    },
    computed: {
        isNumberStateValid() {
            if (this.mobileNumber) {
                return this.isValidNumber(this.mobileNumber);
            }
            return null;
        },
        orders() {
            return this.$store.getters["mails/ordersByTel"];
        },
    },
    methods: {
        isValidNumber() {
            var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
            return re.test(this.mobileNumber) ? true : false;
        },
        checkList(evt) {
            evt.preventDefault();
            if (this.isValidNumber()) {
                this.$store.dispatch("mails/ordersByTel", this.mobileNumber);
                // window.open("tel: " + this.mobileNumber, "_blank");
                this.$bvModal.hide("orderlist-modal");
            }
        },
        openOrderList() {
            this.$bvModal.show("orderlist");
        },
    },
    mounted() {
        this.conversation._embedded.threads.messages.map((elem) => {
            let regex = /Caller\sID\s(\d\d\d\d\d\d\d\d\d\d)/i;
            const found = elem.body.match(regex);
            if (found) {
                this.mobileNumber = found[1];
            }
        });
    },
};
</script>
