<template>
    <b-button
        v-b-tooltip.hover
        title="Vezi mesajul original"
        class="top-0 right-0 position-absolute"
        @click="getOrigConv"
        ><b-icon icon="eye"></b-icon>

        <b-modal
            :id="'orig-conv-' + id"
            title="Conversatie originala"
            hide-footer
            size="lg"
        >
            <div class="vl-parent orig-conv">
                <loading :active="loading" :is-full-page="false" />
                <p v-if="content" v-html="content" class="my-4"></p>
            </div>
        </b-modal>
    </b-button>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
    data() {
        return {
            loading: false,
        };
    },
    props: ["id"],
    computed: {
        content() {
            return this.$store.getters["mails/originalConversation"];
        },
        conversation() {
            return this.$store.getters["mails/conversation"];
        },
    },
    components: { Loading },
    methods: {
        getOrigConv() {
            // if (this.content != "") {
            //     this.$bvModal.show("orig-conv-" + this.id);
            //     return;
            // }
            this.$store.dispatch("mails/resetOriginalConversation");
            this.$bvModal.show("orig-conv-" + this.id);
            this.loading = true;
            this.$store
                .dispatch("mails/getOriginalConversation", this.id)
                .then((response) => {
                    // console.log(response);
                    this.loading = false;
                });
        },
    },
    // destroyed() {
    //     this.$store.dispatch("mails/resetOriginalConversation");
    // },
};
</script>

<style>
.orig-conv {
    min-height: 200px;
}
</style>
