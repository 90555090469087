<template>
    <v-dialog v-model="spamDialog" max-width="400">
        <EditRuleDialog
            :showItem="showItem"
            :dialog="dialog"
            @close-dialog="closeDialog"
            @workflow-deleted="workflowDeleted"
        />
        <v-card class="spam-dialog" v-if="false">
            <v-card-title class="headline" style="word-break: break-word"
                >Exista deja o regula pentru acest email
            </v-card-title>
            <v-card-text>
                <v-row v-if="spamWorkflowExist.length > 0" class="mt-4">
                    <v-col
                        v-for="(item, i) in spamWorkflowExist"
                        v-bind:key="i"
                        class="text-black"
                    >
                        {{ item.name }}
                        <template v-if="item.active">
                            <v-chip
                                @click="showWorkflow(item)"
                                success
                                x-small
                                color="green"
                                text-color="white"
                                class="ml-3"
                            >
                                vezi regula
                            </v-chip>
                        </template>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="spamDialog = false">Ok</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
        <v-card class="spam-dialog" v-else>
            <v-card-title
                v-if="selectedMails.length > 1"
                class="headline"
                style="word-break: break-word"
                >Sunteti sigur ca doriti sa trimiteti contactele:
                <span class="text-danger">
                    {{ getMailsAddress() }}
                </span>
                in spam si toate conversatile viitoare aferente acestor adrese din
                departamentul
                {{ mailboxes }}?
            </v-card-title>
            <v-card-title v-else class="headline" style="word-break: break-word"
                >Sunteti sigur ca doriti sa trimiteti acest contact
                <span class="text-danger">
                    {{ getMailsAddress() }}
                </span>
                in spam si toate conversatile viitoare aferente acestei adrese din
                departamentul
                {{ mailboxes }}?</v-card-title
            >

            <v-card-text>
                <v-checkbox v-model="addRule"
                    ><template class="mb-0" v-slot:label>
                        <div>Adauga regula pe toate departamentele</div>
                    </template>
                </v-checkbox>
            </v-card-text>

            <v-card-text>
                <v-row v-if="errorString">
                    <v-col class="text-danger" v-if="errorType == 'error'">
                        Eroare: {{ errorString }}
                    </v-col>
                    <v-col class="text-info" v-if="errorType == 'info'">
                        {{ errorString }}
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-text v-if="workflow_exists.length > 0">
                <v-row>
                    <v-col
                        v-for="(item, i) in workflow_exists"
                        v-bind:key="i"
                        class="text-black"
                    >
                        Departament: <b> {{ item.mailbox }} </b> <br />
                        {{ item.name }}
                        <template v-if="item.active">
                            <v-chip
                                @click="showWorkflow(item)"
                                success
                                x-small
                                color="green"
                            >
                                vezi regula
                            </v-chip>
                            <!-- <v-chip success x-small color="green"> vezi regula </v-chip> -->
                        </template>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="red darken-1" text @click="spamDialog = false">
                    Renunta
                </v-btn>

                <v-btn color="green darken-1" text @click="mailSpam()"> De acord </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { GET_WORKFLOWS } from "@/core/services/store/modules/workflow";
import { DELETE_WORKFLOW } from "@/core/services/store/modules/workflow";
import { SPM_WRK } from "@/core/services/store/modules/workflow";
import { mapActions, mapGetters } from "vuex";
import EditRuleDialog from "@/view/pages/workflows/EditRuleDialog.vue";

export default {
    data() {
        return {
            mailboxes: "",
            addRule: false,
            spamWorkflowExist: false,
            dialog: false,
            showItem: {},
            workflow_exists: [],
            errorString: "",
        };
    },
    props: ["conversation", "spamDialog", "inConversation"],
    watch: {
        spamDialog() {
            this.$emit("input", this.spamDialog);
        },
    },
    components: {
        EditRuleDialog,
    },
    computed: {
        departments() {
            return this.$store.getters["departments/allDepartments"];
        },
        ...mapGetters("mails", ["selectedMails"]),
        ...mapGetters("mails", ["newMails"]),
        ...mapActions("mails", ["openModal"]),
        ...mapActions("mails", ["spamMail"]),
    },
    methods: {
        closeUndo() {
            this.spamDialog = false;
            this.$emit("input", this.spamDialog);
        },
        mailSpam() {
            let payload = {
                departments: this.departments,
                addRuleForAll: this.addRule,
                conversation: this.conversation,
                status: "spam",
            };
            // console.log(this.conversation);
            // this.$store.dispatch("mails/spamMail", payload)
            this.$store.dispatch(SPM_WRK, payload).then((response) => {
                if (response.errors) {
                    this.errorType = "error";
                    if (response.errors && response.errors.workflow_exists) {
                        this.workflow_exists = response.errors.workflow_exists;
                        this.errorString =
                            "Exista o regula pe urmatoarele departamente. Mai intai trebuie stearsa!";
                    } else {
                        for (const property in response.errors) {
                            this.errorString +=
                                response.message + ". " + response.errors[property];
                        }
                    }
                } else {
                    this.errorString = "";
                    // this.$emit("reload-conversation", true);
                    this.spamDialog = false;
                    // this.email = "";
                    this.operator = "equal";
                    this.$store.commit("alerts/setNewAlert", {
                        message_header: "Adaugare regula",
                        message_content: "Regula: a fost adaugata cu succes",
                        variant: "success",
                    });
                }
            });
            // .then((response) => {
            //     console.log(response);
            // });

            // this.$router.push("spam-mails");
        },
        removeRule(departId) {
            //lista cu workflow-urile din departamentul conversatiei
            let payload = {
                mailboxId: departId,
            };
            this.$store.dispatch(GET_WORKFLOWS, payload).then((response) => {
                // se cauta daca exista regula
                response.automatic.map((elem) => {
                    if (
                        elem.name ===
                        "Muta in Spam -> " + this.conversation.customer.email
                    ) {
                        // se sterge regula
                        this.$store.dispatch(DELETE_WORKFLOW, elem.id).then(() => {
                            this.$store.dispatch(
                                "mails/getConversation",
                                this.conversation.id
                            );
                        });
                    }
                });
            });
        },
        undoItemConfirm() {
            this.$store
                .dispatch("mails/undoSpamStatus", { id: this.conversation.id })
                .then(() => {
                    // this.$store.dispatch("mails/getMailsInSpam");
                    //lista cu workflow-urile din departamentul conversatiei
                    this.removeRule(this.conversation.mailboxId);
                    // se cauta si se sterge regula din celelalte departamente
                    if (this.deleteRule) {
                        this.departments.map((depart) => {
                            if (depart.id !== this.conversation.mailboxId) {
                                this.removeRule(depart.id);
                            }
                        });
                    }
                });
            // modificam statul sa nu mai fie spam
            this.closeUndo();
        },
        getMailsAddress() {
            // TODO: se apeleaza de prea multe ori functia, si ar trebui sa se apeleze mai putin
            if (this.conversation) {
                this.mailboxes = this.conversation.departament;
                return this.conversation.customer.email;
            } else {
                let addressEmail = "";
                let mailboxes = "";
                let mails = this.newMails.filter((elem) => {
                    let selectedId = false;
                    this.selectedMails.forEach((mailId) => {
                        if (mailId === elem.id) selectedId = true;
                    });
                    if (selectedId && !mailboxes.includes(elem.departament)) {
                        mailboxes = mailboxes + elem.departament + ", ";
                        //   this.mailboxes += elem.department
                    }
                    if (selectedId && !addressEmail.includes(elem.customer.email))
                        addressEmail += elem.customer.email + ", ";

                    return selectedId;
                });
                this.mailboxes = mailboxes.substring(0, mailboxes.length - 2);
                return addressEmail;
            }
        },
        checkSpamWorkflow() {
            let spamWorkflows = [];
            this.conversation._embedded.threads.workflows.map((elem) => {
                if (elem._embedded && elem._embedded.workflow) {
                    if (
                        elem._embedded.workflow.name ==
                            "Mutare in spam -> " + this.conversation.customer.email &&
                        elem._embedded.workflow.active
                    ) {
                        spamWorkflows.push(elem._embedded.workflow);
                    }
                }
            });
            this.spamWorkflowExist = spamWorkflows.length > 0 ? spamWorkflows : false;
        },
        showWorkflow(item) {
            this.showItem = item;
            this.dialog = true;
        },
        closeDialog() {
            this.dialog = false;
        },
        workflowDeleted(rule) {
            // this.$emit("reload-conversation", true);
            const i = this.workflow_exists.map((item) => item).indexOf(rule);
            this.workflow_exists.splice(i, 1);
            if (this.workflow_exists.length == 0) {
                this.errorString =
                    "Toate regulile au fost sterse. Acum puteti adauga regula noua!";
                this.errorType = "info";
            }
            this.dialog = false;
        },
    },
    mounted() {
        this.checkSpamWorkflow();
    },
};
</script>

<style>
.spam-dialog .v-label {
    margin-bottom: 0px !important;
}
</style>
