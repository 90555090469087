<template>
    <div>
        <b-button
            variant="outline-secondary"
            @click="$bvModal.show('modal-forward-options-' + conversation.id), getData()"
        >
            <i class="la la-mail-forward"></i>Redirectioneaza</b-button
        >
        <b-modal
            :id="`modal-forward-options-` + conversation.id"
            title="Optiuni redirectionare"
        >
            <b-form-group label="" v-slot="{ ariaDescribedby }">
                <b-form-radio
                    v-model="selected"
                    :aria-describedby="ariaDescribedby"
                    name="some-radios"
                    value="current"
                    >Mail curent</b-form-radio
                >
                <b-form-radio
                    v-model="selected"
                    :aria-describedby="ariaDescribedby"
                    name="some-radios"
                    value="all"
                    >Toate conversatia pana la mailul curent</b-form-radio
                >
            </b-form-group>

            <!-- <div v-html="final_forward"></div> -->

            <template #modal-footer>
                <div class="w-100">
                    <b-button
                        variant="primary"
                        class="float-right"
                        @click="newConversation"
                    >
                        Inainte
                    </b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    props: ["conversation"],
    data() {
        return {
            selected: "current",
            all_body: "",
            final_forward: "",
            current_block: "",
            forwardConversation: {},
        };
    },
    computed: {
        parentConversation() {
            return this.$store.getters["mails/conversation"];
        },
    },
    methods: {
        getData() {
            // console.log("curent conversation", this.conversation);
            // console.log("parent conversation", this.parentConversation);

            // search index
            const index = this.parentConversation._embedded.threads.messages.findIndex(
                (conv) => conv.id == this.conversation.id
            );

            let conversations = this.parentConversation._embedded.threads.messages;

            // console.log(conversations);

            for (let i = conversations.length - 1; i > index; i--) {
                let email =
                    conversations[i].createdBy.type === "user"
                        ? this.parentConversation.mailboxEmail
                        : conversations[i].createdBy.email;
                let block =
                    "<div>" +
                    '<div dir="ltr" class="gmail_attr">În ' +
                    this.formatDate(conversations[i].createdAt) +
                    ", " +
                    this.getName(conversations[i].createdBy) +
                    " " +
                    '&lt;<a href="mailto:' +
                    email +
                    '" target="_blank">' +
                    email +
                    "</a>&gt; a scris:</div>";

                block +=
                    "<div class='blockquote-forward' style='margin: 0px 0px 0px 0.8ex; border-left: 1px solid rgb(204,204,204); padding-left: 1ex'>" +
                    conversations[i].body +
                    this.all_body +
                    "</div></div>";
                this.all_body = block;
            }

            let email =
                this.conversation.createdBy.type === "user"
                    ? this.parentConversation.mailboxEmail
                    : this.conversation.createdBy.email;
            this.current_block =
                '<div dir="ltr" class="gmail_attr"><p>---------- Mesaj redirectionat ---------</p>' +
                '<p>De la: <strong class="gmail_sendername" dir="auto">' +
                this.getName(this.conversation.createdBy) +
                '</strong> <span dir="auto">&lt;<a href="mailto:' +
                email +
                '" target="_blank">' +
                email +
                "</a>&gt;</span><br></p>" +
                "<p>Data: " +
                this.formatDate(this.conversation.createdAt) +
                "</p>" +
                "<p>Subiect: Re: " +
                this.parentConversation.subject +
                "</p>" +
                "<p>Catre: " +
                this.conversation.to[0] +
                '&lt;<a href="mailto:' +
                this.conversation.to[0] +
                '" target="_blank">' +
                this.conversation.to[0] +
                "</a>&gt;</p></div>" +
                this.conversation.body;

            // this.final_forward = current_block + this.all_body;

            // console.log("parent conversation 2", this.final_forward);

            // scot totul pana la index
            //iterez prin array si concatenez string-ul
        },
        newConversation() {
            // deschid o noua conversatie cu acest body
            this.final_forward = this.current_block;
            if (this.selected == "all") {
                this.final_forward += this.all_body;
            }
            this.forwardConversation.text = this.final_forward;
            this.forwardConversation.attachments = this.getAttachments(
                this.parentConversation
            );
            this.$bvModal.hide("modal-forward-options-" + this.conversation.id);
            this.$store
                .dispatch("mails/forwardMail", this.forwardConversation)
                .then(() => {
                    this.all_body = "";
                });
            this.$bvModal.show("new-conv-modal");
            // this.final_forward = this.current_block + this.all_body;
        },
        formatDate(date_created) {
            const date = new Date(date_created);
            return `${("0" + date.getDate()).slice(-2)}-${(
                "0" +
                (date.getMonth() + 1)
            ).slice(-2)}-${date.getFullYear()} ${("0" + date.getHours()).slice(-2)}:${(
                "0" + date.getMinutes()
            ).slice(-2)}`;
        },
        getName(person) {
            let name = person.firstName
                ? person.firstName
                : "" + person.lastName
                ? person.lastName
                : "";
            if (name !== "") {
                return name;
            }
            return "fara nume";
        },
        getAttachments(parentConversation) {
            let attachments = [];

            // if current
            if (this.selected === "current") {
                let avAttachments = this.conversation._embedded.attachments;
                if (avAttachments.length) {
                    avAttachments.map((attachment) => {
                        attachments.push(attachment);
                    });
                }
            } else {
                const index = this.parentConversation._embedded.threads.messages.findIndex(
                    (conv) => conv.id == this.conversation.id
                );

                let conversations = parentConversation._embedded.threads.messages;

                for (let i = conversations.length - 1; i >= index; i--) {
                    let avAttachments = conversations[i]._embedded.attachments;
                    if (avAttachments.length) {
                        avAttachments.map((attachment) => {
                            attachments.push(attachment);
                        });
                    }
                }
            }
            // else all
            return attachments;
        },
    },
};
</script>
