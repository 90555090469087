var render = function render(){var _vm=this,_c=_vm._self._c;return _c('KTCodePreview',{staticClass:"conversation-buttons-wrapper",attrs:{"title":'Agent: ' + _vm.agentName},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",modifiers:{"hover":true,"top":true}}],staticClass:"symbol symbol-35 symbol-light mr-1 flex-shrink-0",attrs:{"title":_vm.conversation.departament}},[_c('div',{staticClass:"symbol-label"},[_c('span',{staticClass:"svg-icon svg-icon-2x svg-icon-primary"},[_c('inline-svg',{attrs:{"src":_vm.getSVG(_vm.conversation.departament)}})],1)])]),_c('h3',{staticClass:"card-label"},[_c('span',{staticClass:"text-muted font-weight-bold"},[_vm._v("Agent: ")]),_vm._v(" "+_vm._s(_vm.agentName)+" ")])]},proxy:true},(_vm.conversation.statusName)?{key:"preview",fn:function(){return [_c('div',[_c('h3',{staticClass:"d-flex align-items-center"},[_vm._v(" Stare: "+_vm._s(_vm.conversation.statusName)+" "),(_vm.conversation.threadMessage)?_c('LabelButton',{staticClass:"ml-1 mr-1",attrs:{"message":_vm.conversation.threadMessage,"id":_vm.conversation.id,"status":_vm.conversation.status}}):_vm._e(),(_vm.conversation.pendingStatus)?_c('LabelButton',{staticClass:"ml-1 mr-1",attrs:{"message":_vm.conversation.pendingStatus,"id":_vm.conversation.id,"status":_vm.conversation.status}}):_vm._e()],1)]),_c('div',[(_vm.conversation.status === 'open')?_c('process-buttons',{attrs:{"conversation":_vm.conversation},on:{"mailaction":_vm.mailaction}}):_vm._e(),(
                    _vm.conversation.status === 'active' ||
                    _vm.conversation.status === 'pending'
                )?_c('b-button',{staticClass:"mr-3 process-button",on:{"click":function($event){return _vm.processAsignee()}}},[_vm._v("Proceseaza")]):_vm._e(),(_vm.conversation.status === 'closed')?_c('b-button',{staticClass:"mr-3 process-button",on:{"click":function($event){return _vm.processAsignee()}}},[_vm._v("Reproceseaza")]):_vm._e()],1),_c('div',{staticClass:"col-lg-12 col-md-12 conv-btn-pass"},[_c('div',{staticClass:"conversation-pass"},[_c('v-form',{ref:"pasareForm",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[(_vm.conversation.status !== 'closed')?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"pb-2 pt-2 font-weight-bolder text-dark"},[_vm._v("Asigneaza ticket:")])]),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-select',{attrs:{"items":_vm.eachDepartments,"rules":[(v) => !!v || 'Campul este obligatoriu'],"dense":"","outlined":"","required":""},on:{"change":function($event){return _vm.getPasareDeparts()}},model:{value:(_vm.passDepartId),callback:function ($$v) {_vm.passDepartId=$$v},expression:"passDepartId"}})],1),(_vm.showSelectUser)?_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-select',{attrs:{"disabled":!_vm.showSelectUser,"items":_vm.departmentUsers,"menu-props":"auto","dense":"","outlined":""},on:{"change":function($event){return _vm.getDepartUsers()}},scopedSlots:_vm._u([{key:"item",fn:function({ active, item, attrs, on }){return [_c('v-list-item',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"default",fn:function({ active }){return [_c('v-list-item-content',[_c('v-list-item-title',[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('span',[_vm._v(_vm._s(item.text))]),_c('v-spacer'),(item.value)?_c('span',{staticClass:"label label-rounded label-light-success font-weight-bolder ml-1",class:[
                                                            item.status === 'active'
                                                                ? 'label-light-success'
                                                                : '',
                                                            item.status === 'vacation'
                                                                ? 'label-light-danger'
                                                                : '',
                                                            item.status === 'busy'
                                                                ? 'label-light-warning'
                                                                : '',
                                                        ]},[_vm._v(_vm._s(item.count)+" ")]):_vm._e()],1)],1)],1)]}}],null,true)},'v-list-item',attrs,false),on))]}}],null,false,2727827336),model:{value:(_vm.passUserId),callback:function ($$v) {_vm.passUserId=$$v},expression:"passUserId"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('div',[_c('v-btn',{staticClass:"spam-btn btn-paseaza",attrs:{"type":"submit"},on:{"click":_vm.mailAssignee}},[_vm._v("PASEAZA")])],1)])],1):_vm._e()],1)],1)])]},proxy:true}:null,{key:"buttons",fn:function(){return [_c('ActionsHistory',{attrs:{"conversation":_vm.conversation}})]},proxy:true}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }