<template>
    <div>
        <div class="card card-custom gutter-b">
            <div class="card-header">
                <div class="card-title">
                    <h3 class="card-label">Discutie Interna</h3>
                </div>
                <div class="card-toolbar">
                    <b-icon-chevron-down
                        v-show="!showInfoClient"
                        v-b-toggle.collapse-chat
                    ></b-icon-chevron-down>
                </div>
            </div>
            <b-collapse :visible="showInfoClient" id="collapse-chat" class="mt-2">
                <div style="max-width: 600px" class="m-auto">
                    <div
                        class="card-body message-body"
                        v-if="
                            conversation._embedded.threads.notes &&
                            conversation._embedded.threads.notes.length > 0
                        "
                    >
                        <ChatItem
                            v-for="(message, index) in conversation._embedded.threads
                                .notes"
                            :key="index"
                            :message="message"
                            :prev="getPrevElement(index)"
                        ></ChatItem>
                    </div>
                    <div v-else class="card-body message-body">
                        <p class="text-muted">Nu sunt mesaje</p>
                    </div>
                    <div class="card-footer sa">
                        <b-form @submit="sendNotes" inline>
                            <b-input
                                id="inline-form-input-name"
                                class="mr-2 mb-sm-0 col"
                                placeholder="Scrie mesaj"
                                v-model="note"
                                required
                            ></b-input>

                            <b-button type="submit" class="col-auto p-2" variant="primary"
                                ><b-icon
                                    icon="cursor-fill"
                                    rotate="45"
                                    style="width: 20px; height: 20px"
                                    aria-hidden="true"
                                ></b-icon
                            ></b-button>
                        </b-form>
                    </div>
                </div>
            </b-collapse>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ChatItem from "@/view/pages/conversation/chat-comp/ChatItem";

export default {
    data() {
        return {
            note: "",
            events: [],
            input: null,
            urlParam: this.$route.params.id,
        };
    },
    components: {
        ChatItem,
    },

    computed: {
        ...mapActions("threads", ["getThreads"]),
        ...mapGetters(["user"]),
        ...mapGetters("mails", ["conversation"]),
        showInfoClient() {
            return window.innerWidth > 1264;
        },
    },

    methods: {
        getPrevElement(index) {
            if (
                this.conversation._embedded.threads.notes &&
                this.conversation._embedded.threads.notes[index - 1]
            ) {
                return this.conversation._embedded.threads.notes[index - 1];
            } else {
                return undefined;
            }
        },
        sendNotes(evt) {
            evt.preventDefault();
            let payload = {};
            payload.mailData = {
                type: "note",
                text: this.note,
                user: this.user.id,
            };
            payload.id = this.conversation.id;
            this.$store.dispatch("mails/replyThread", payload).then(() => {
                this.note = "";
            });
        },
    },
    mounted() {},
};
</script>

<style scoped>
.v-card__title.title {
    background: grey;
    color: white;
    padding: 5px 10px;
    font-size: 13px !important;
    line-height: 1rem;
}

.shma-chat-arrow:before,
.shma-chat-arrow:after {
    transform: rotate(0);
    left: -5px;
    right: auto;

    content: "";
    position: absolute;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid gray;
    top: 6px;
}

.chat-body-message {
    padding: 5px 10px;
}

.chat-body-message p {
    margin-bottom: 0;
}

.message-body {
    max-height: 400px;
    overflow: auto;
}
</style>
