var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"messages"},[(_vm.message.createdBy.id !== _vm.user.id)?_c('div',{staticClass:"d-flex flex-column align-items-start"},[(_vm.prev == undefined || _vm.message.createdBy.id != _vm.prev.createdBy.id)?_c('div',{staticClass:"d-flex align-items-center mb-1 mt-3"},[_c('span',{staticClass:"symbol symbol-40 mr-3"},[(
                        _vm.message.createdBy.photoUrl !=
                        'https://sharedmailapi.pefoc.ro/img/default-avatar.png'
                    )?_c('img',{attrs:{"alt":"Pic","src":_vm.message.createdBy.photoUrl}}):_c('span',{staticClass:"symbol-label font-size-h5 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.message.createdBy.firstName.charAt(0) + _vm.message.createdBy.lastName.charAt(0))+" ")])]),_c('div',[_c('a',{staticClass:"text-dark-75 text-hover-primary font-weight-bold font-size-h6 mr-3",attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.message.createdBy.firstName)+" "+_vm._s(_vm.message.createdBy.lastName))]),_c('span',{staticClass:"text-muted font-size-sm"},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.message.createdAt,"DD-MM-YY HH:mm")))])])]):_vm._e(),_c('div',{staticClass:"rounded p-3 mb-1 bg-light-success text-dark-50 font-weight-bold font-size-lg text-left max-w-400px",domProps:{"innerHTML":_vm._s(_vm.message.body)}})]):_c('div',{staticClass:"d-flex flex-column align-items-end"},[(_vm.prev == undefined || _vm.message.createdBy.id != _vm.prev.createdBy.id)?_c('div',{staticClass:"d-flex align-items-center mb-1"},[_c('div',[_c('span',{staticClass:"text-muted font-size-sm"},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.message.createdAt,"DD-MM-YY HH:mm")))]),_c('a',{staticClass:"text-dark-75 text-hover-primary font-weight-bold font-size-h6 ml-3",attrs:{"href":"#"}},[_vm._v("You")])]),_c('span',{staticClass:"symbol symbol-40 ml-3"},[(
                        _vm.message.createdBy.photoUrl !=
                        'https://sharedmailapi.pefoc.ro/img/default-avatar.png'
                    )?_c('img',{attrs:{"alt":"Pic","src":_vm.message.createdBy.photoUrl}}):_c('span',{staticClass:"symbol-label font-size-h5 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.message.createdBy.firstName.charAt(0) + _vm.message.createdBy.lastName.charAt(0))+" ")])])]):_vm._e(),_c('div',{staticClass:"mb-1 rounded p-3 bg-light-primary text-dark-50 font-weight-bold font-size-lg text-right max-w-400px",domProps:{"innerHTML":_vm._s(_vm.message.body)}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }