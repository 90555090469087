<template>
    <div v-if="conversation.id">
        <loading :active="isLoading" />
        <div class="row">
            <div class="col-12 col-lg-4">
                <div class="card card-custom gutter-b">
                    <div class="card-header">
                        <div class="card-title">
                            <b-button-toolbar
                                aria-label="Toolbar with button groups and dropdown menu"
                            >
                            </b-button-toolbar>
                            <h3 class="card-label">Info Client</h3>
                        </div>
                        <div class="card-toolbar">
                            <span
                                v-if="conversation._embedded.threads.workflows"
                                class="svg-icon svg-icon-2x"
                                :class="{
                                    'svg-icon-danger': catchByRule,
                                    'svg-icon-secondary': !catchByRule,
                                }"
                                @click="openRules"
                            >
                                <inline-svg src="/media/svg/icons/Text/Filter.svg" />
                                <WorkflowDialog
                                    :workflows="conversation._embedded.threads.workflows"
                                    @reload-conversation="reloadConversation"
                                />
                            </span>

                            <b-icon-chevron-down
                                v-show="!showInfoClient"
                                v-b-toggle.collapse-info-client
                            ></b-icon-chevron-down>
                        </div>
                    </div>
                    <b-collapse
                        id="collapse-info-client"
                        :visible="showInfoClient"
                        class="mt-2"
                    >
                        <div class="card-body">
                            <!--begin::Contact-->
                            <div class="">
                                <div
                                    class="d-flex align-items-center justify-content-between mb-2"
                                >
                                    <span class="font-weight-bold mr-2">Nume:</span>
                                    <p class="text-muted text-hover-primary">
                                        {{ conversation.customer.firstName }}
                                        {{ conversation.customer.lastName }}
                                    </p>
                                </div>
                                <div
                                    class="d-flex align-items-center justify-content-between mb-2"
                                >
                                    <span class="font-weight-bold mr-2">Email:</span>
                                    <a href="#" class="text-muted text-hover-primary">{{
                                        conversation.customer.email
                                    }}</a>
                                </div>
                            </div>
                            <!--end::Contact-->
                        </div>
                    </b-collapse>
                    <div class="card-footer">
                        <b-button-group class="btn-group">
                            <b-button
                                v-if="mails.length > 0"
                                @click="openClockModal()"
                                class="mr-1"
                                :variant="recently ? 'danger' : ''"
                            >
                                <b-icon icon="clock"></b-icon>

                                <LogTicketModal :mails="mails" />
                            </b-button>
                            <AddRuleButton
                                v-if="conversation"
                                :conversation="conversation"
                            />
                            <SpamButton
                                v-if="conversation.status !== 'spam'"
                                :conversation="conversation"
                                :inConversation="true"
                                variant="danger"
                                @reload-conversation="reloadConversation"
                            />

                            <UnSpamButton v-else :conversation="conversation" />
                        </b-button-group>

                        <b-button-group class="float-right">
                            <OrderListButton :conversation="conversation" />
                            <CallC3XButton :conversation="conversation" />
                        </b-button-group>
                    </div>
                </div>

                <Chat></Chat>
            </div>

            <div class="col-12 col-lg-8">
                <div class="conv-modal-wrapper">
                    <ConvModal @assignee="loading"></ConvModal>
                </div>
                <!--begin::Row-->
                <div class="row">
                    <div class="col-lg-12">
                        <ConvButtons @assignee="loading"></ConvButtons>
                    </div>
                </div>
                <!--end::Row-->

                <MailReply :conversation="conversation"></MailReply>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Chat from "@/view/pages/conversation/Chat.vue";
import ConvButtons from "@/view/pages/conversation/ConvButtons";
import ConvModal from "@/view/pages/conversation/ConvModal";
import MailReply from "@/view/pages/conversation/MailReply";
import LogTicketModal from "@/view/pages/conversation/LogTicketModal";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import AddRuleButton from "@/view/pages/conversation/AddRuleButton";
import UnSpamButton from "@/view/content/buttons/UnSpamButton";
import CallC3XButton from "@/view/content/buttons/CallC3XButton";
import OrderListButton from "@/view/content/buttons/OrderListButton";
import SpamButton from "@/view/content/buttons/SpamButton";
import WorkflowDialog from "@/view/pages/conversation/workflows/WorkflowDialog";
import moment from "moment";
import { CLIENT_DIALOG } from "@/core/services/store/modules/workflow";

export default {
    data() {
        return {
            urlParam: this.$route.params.id,
            isLoading: false,
            confirmSpam: false,
            mails: [],
            recently: false,
        };
    },
    components: {
        Chat,
        ConvButtons,
        ConvModal,
        MailReply,
        Loading,
        LogTicketModal,
        AddRuleButton,
        UnSpamButton,
        SpamButton,
        CallC3XButton,
        OrderListButton,
        WorkflowDialog,
    },
    computed: {
        ...mapGetters(["currentUserPhoto"]),
        ...mapGetters("mails", ["conversation"]),
        ...mapGetters("departments", ["departments"]),
        ...mapGetters(["user"]),
        showInfoClient() {
            return window.innerWidth > 1264;
        },
        catchByRule() {
            let isActiveRule = false;
            if (this.conversation._embedded.threads.workflows) {
                this.conversation._embedded.threads.workflows.map((workflow) => {
                    if (
                        workflow._embedded.workflow !== null &&
                        workflow._embedded.workflow.active
                    ) {
                        isActiveRule = true;
                        return;
                    }
                });
            }
            return isActiveRule;
        },
    },
    methods: {
        loading(event) {
            this.isLoading = event;
            // simulate AJAX
            setTimeout(() => {
                this.isLoading = false;
            }, 1000);
        },
        mailSpam() {
            this.$store.commit("mails/setResetSelected");
            this.$store.commit("mails/selectMail", this.conversation.id);
            this.$store.dispatch("mails/spamMail");
            this.confirmSpam = false;
            this.$router.push("/");
        },
        redirect() {
            this.$router.push({ name: "user", params: { user: this.user.id } });
            this.$store.commit("alerts/setNewAlert", {
                message_header: "Acces pagina",
                message_content: "Nu ai permisiunea sa accesezi acest mail.",
                variant: "danger",
            });
        },
        hasAccessToConv(departments, conversation) {
            let depart = departments.find((dep) => dep.id === conversation.mailboxId);
            if (conversation.is_following) {
                return;
            }

            if (conversation.status == "spam") {
                return;
            }
            // nu e departamentul lui face redirect
            if (!depart) {
                // console.log("fa redirect 1");
                this.redirect();
            }

            // nu e conversatia lui si nu e manager pe departament face redicer
            else if (
                conversation.assignee?.id !== this.user.id &&
                !depart.isManager &&
                conversation.status !== "active"
            ) {
                this.redirect();
            }
        },
        async getConv(id) {
            await this.$store
                .dispatch("mails/getConversation", id)
                .then((conversation) => {
                    this.$store
                        .dispatch("departments/getDepartments")
                        .then((departments) => {
                            this.hasAccessToConv(departments, conversation);
                        });
                });
        },
        openClockModal() {
            this.$bvModal.show("clock-modal");
        },
        openRules() {
            this.$store.dispatch(CLIENT_DIALOG, true);
        },
        reloadConversation() {
            this.getConv(this.urlParam);
        },
    },
    watch: {
        $route(to, from) {
            this.getConv(to.params.id);
            this.$store.dispatch("threads/getConvId", to.params.id);
            this.isLoading = true;
            // simulate AJAX
            setTimeout(() => {
                this.$store.dispatch(SET_BREADCRUMB, [
                    { title: "Email #" + this.conversation.number },
                ]);
                this.isLoading = false;
            }, 1000);
        },
        conversation() {
            // console.log("conversation", this.conversation);
            if (this.conversation && this.conversation.customer) {
                let payload = {
                    customerEmail: this.conversation.customer.email,
                    per_page: 50,
                };

                this.$store.dispatch("mails/getEmails", payload).then((response) => {
                    let mailsWithoutCurrentMail = response.filter((mail) => {
                        return mail.id !== this.conversation.id;
                    });
                    if (mailsWithoutCurrentMail.length) {
                        const fourdaysago = moment()
                            .subtract(4, "d")
                            .format("YYYY-MM-DD");
                        const date = mailsWithoutCurrentMail[0].createdDate;
                        if (moment(date).isAfter(fourdaysago)) {
                            this.recently = true;
                        }
                        this.mails = mailsWithoutCurrentMail;
                    }
                });
                this.$store.dispatch(SET_BREADCRUMB, [
                    { title: "Email #" + this.conversation.number },
                ]);
            }
        },
    },
    created() {
        this.getConv(this.urlParam);
        this.$store.dispatch("threads/getConvId", this.urlParam);
        this.isLoading = true;
        // this.$store.dispatch(SET_BREADCRUMB, [{ title: "Email #" + this.urlParam }]);
        // simulate AJAX
        setTimeout(() => {
            // console.log(this.conversation);

            this.isLoading = false;
        }, 1500);
    },
    mounted() {
        // request pentru conversatii deschie pe acelasi mail
        // console.log(this.conversation);
        // let payload = {
        //   status: "open",
        //   customerEmail: this.conversation.customer.email,
        // };
        // this.$store.dispatch("mails/getEmails", payload).then((response) => {
        //   this.mails = response;
        // });
    },
    destroyed() {
        this.$store.dispatch("mails/resetConversation");
    },
};
</script>
