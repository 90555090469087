<template>
    <b-modal id="orderlist" hide-footer size="xl">
        <template #modal-title>Lista comenzi dupa numarul de telefon</template>
        <div class="d-block text-center">
            <v-data-table
                :headers="headers"
                :items="orders"
                :items-per-page="10"
                mobile-breakpoint="768"
                class="order-by-tel"
            >
                <template v-slot:item.ord_order_number="{ item }">
                    <a
                        :href="`https://octav.pefoc.ro/admin/detalii-comanda/${item.ord_order_number}`"
                        target="_blank"
                        >{{ item.ord_order_number }}</a
                    >
                </template>
                <template v-slot:item.ord_last_update="{ item }">
                    {{ formatDate(item.ord_last_update, true) }}
                </template>
            </v-data-table>
        </div>
    </b-modal>
</template>

<script>
import moment from "moment";
import ConversationLink from "@/view/pages/conversation/ConversationLink";

export default {
    data() {
        return {
            headers: [
                {
                    text: "Nr. comanda",
                    align: "left",
                    sortable: false,
                    value: "ord_order_number",
                    class: "h6 grey lighten-2",
                },
                {
                    text: "Nume client",
                    align: "left",
                    sortable: false,
                    value: "client_name",
                    class: "h6 grey lighten-2",
                },
                {
                    text: "Agent",
                    value: "agent_name",
                    sortable: false,
                    class: "h6 grey lighten-2",
                },
                {
                    text: "Data ultimei modificari",
                    value: "ord_last_update",
                    class: "h6 grey lighten-2",
                },
                { text: "Status", value: "status", class: "h5 grey lighten-2" },
            ],
            statusColor: {
                spam: "#f44336",
                active: "#f3c200",
                open: "#8950FC",
                pending: "#5867dd",
                closed: "#1BC5BD",
            },
        };
    },
    props: ["orders"],
    components: {
        // ConversationLink,
    },
    methods: {
        formatDate(date_created, time = false) {
            const date = new Date(date_created);

            return `${("0" + date.getDate()).slice(-2)}-${(
                "0" +
                (date.getMonth() + 1)
            ).slice(-2)} ${date.getFullYear()} ${
                time ? date.getHours() + ":" + ("0" + date.getMinutes()).slice(-2) : ""
            }`;
        },
        closeModal() {
            this.$bvModal.hide("orderlist");
        },
    },
};
</script>

<style>
.order-by-tel table tr:nth-child(even) {
    background: #f9f9f9;
}
</style>
