<template>
    <v-dialog v-model="spamDialog" max-width="440px">
        <v-card class="spam-dialog">
            <v-card-title class="text-h6" style="word-break: break-word">
                <p v-if="conversation">
                    Aceasta conversatie, precum si cele viitoare din departamentul
                    <strong class="text-primary">{{ conversation.departament }} </strong>
                    vor fi restaturate.
                </p>
            </v-card-title>
            <v-card-text>
                <v-checkbox v-model="deleteRule" hide-details
                    ><template class="mb-0" v-slot:label>
                        <div>Sterge regula de pe toate departamentele</div>
                    </template>
                </v-checkbox>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" @click="closeUndo" text>Cancel</v-btn>
                <v-btn color="blue darken-1" @click="undoItemConfirm" text>OK</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { GET_WORKFLOWS } from "@/core/services/store/modules/workflow";
import { DELETE_WORKFLOW } from "@/core/services/store/modules/workflow";

export default {
    data() {
        return {
            spamDialog: false,
            deleteRule: "",
        };
    },
    props: ["value", "conversation"],
    watch: {
        value() {
            this.spamDialog = this.value;
        },
        spamDialog() {
            this.$emit("input", this.spamDialog);
        },
    },
    computed: {
        departments() {
            return this.$store.getters["departments/allDepartments"];
        },
    },
    methods: {
        closeUndo() {
            this.spamDialog = false;
            this.$emit("input", this.spamDialog);
        },
        removeRule(departId) {
            var ruleExist = false;
            //lista cu workflow-urile din departamentul conversatiei
            const payload = {
                mailboxId: departId,
                params: {
                    per_page: 1000,
                    search: this.conversation.customer.email,
                },
            };
            this.$store
                .dispatch(GET_WORKFLOWS, payload)
                .then((response) => {
                    // se cauta daca exista regula
                    // console.log(response);
                    response.automatic.map((elem) => {
                        if (
                            elem.name ===
                                "Mutare in spam -> " + this.conversation.customer.email &&
                            elem.active
                        ) {
                            ruleExist = true;
                            // se sterge regula
                            this.$store
                                .dispatch(DELETE_WORKFLOW, elem.id, this.conversation)
                                .then(() => {
                                    this.$store.dispatch(
                                        "mails/getConversation",
                                        this.conversation.id
                                    );
                                });
                        }
                    });
                })
                .then(() => {
                    if (!ruleExist) {
                        this.$store.dispatch(
                            "mails/getConversation",
                            this.conversation.id
                        );
                        this.$store.commit("alerts/setNewAlert", {
                            message_header: "Stergere regula",
                            message_content:
                                "Regula nu exista, intoarcem doar mailul din SPAM",
                            variant: "danger",
                        });
                    }
                });
        },
        undoItemConfirm() {
            this.$store
                .dispatch("mails/undoSpamStatus", { id: this.conversation.id })
                .then(() => {
                    //   this.$store.dispatch("mails/getMailsInSpam");
                    this.$emit("un-spam");
                    //lista cu workflow-urile din departamentul conversatiei
                    this.removeRule(this.conversation.mailboxId);
                    // se cauta si se sterge regula din celelalte departamente
                    if (this.deleteRule) {
                        this.departments.map((depart) => {
                            if (depart.id !== this.conversation.mailboxId) {
                                this.removeRule(depart.id);
                            }
                        });
                    }
                });
            // modificam statul sa nu mai fie spam
            this.closeUndo();
        },
    },

    mounted() {
        if (this.departments && this.departments.length === 0) {
            this.$store.dispatch("departments/getPasareDepartments");
        }
    },
};
</script>

<style>
.spam-dialog .v-label {
    margin-bottom: 0px !important;
}
</style>
