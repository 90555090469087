<template>
    <div>
        <AttachmentList
            :tempAttachments="getTempAttachments"
            :attachments="getAttachments"
            @removeFile="removeFile"
            v-if="attachments.length > 0 || tempAttachments.length > 0"
            :showRemove="true"
        />
        <vue-dropzone
            :useCustomSlot="true"
            v-if="dropzoneShow"
            @vdropzone-upload-progress="uploadProgress"
            :options="dropzoneOptions"
            @vdropzone-file-added="fileAdded"
            @vdropzone-sending="sendingFiles"
            @vdropzone-success="success"
            @vdropzone-sending-multiple="sendingFiles"
            @vdropzone-success-multiple="success"
            ref="myReplayDropzone"
        >
            <div class="dropzone-custom-content">
                <h3 class="text-success">Drag and drop pentru incarcare fisiere!</h3>
                <div class="subtitle">
                    ...sau click pentru incarcare fisier din computer
                </div>
                <div v-if="sizeExcedded" class="subtitle text-danger">
                    S-a depasit limita maxima: 25 MB
                </div>
            </div>
        </vue-dropzone>
    </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import JwtService from "@/core/services/jwt.service";
import AttachmentList from "./AttachmentList";
export default {
    name: "AttachmentZone",
    props: {
        dropzoneShow: {
            type: Boolean,
        },
        attachments: {
            type: Array,
        },
    },
    watch: {
        attachments() {
            //   console.log(this.attachments);
        },
        tempAttachments() {
            // console.log(this.tempAttachments);
        },
    },
    data: () => ({
        tempAttachments: [],
        dropzoneOptions: {
            url: "https://sharedmailapi.pefoc.ro/api/v1/attachments",
            parallelUploads: 10,
            thumbnailWidth: 150,
            maxFilesize: 25,
            addRemoveLinks: true,
            autoProcessQueue: true,
            paramName: function (n) {
                return "file";
            },
            previewsContainer: false,
            headers: {
                "x-Authorization": `Bearer ${JwtService.getToken()}`,
                "X-FreeScout-API-Key": "51351ff4b2bd5456fef3f42380b17250",
            },
        },
        totalSize: 0,
        sizeExcedded: false,
    }),
    components: { vueDropzone: vue2Dropzone, AttachmentList },
    methods: {
        fileAdded(file) {
            // check total limit size
            this.totalSize += file.size;
            let floatTotalSize = parseFloat(
                (this.totalSize / (1024 * 1024)).toFixed(2)
            );

            if (floatTotalSize > 25) {
                this.sizeExcedded = true;
                this.totalSize -= file.size;
                this.$refs.myReplayDropzone.removeFile(file);
                return;
            } else {
                this.sizeExcedded = false;
            }

            // Construct your file object to render in the UI
            let attachment = {};
            attachment._id = file.upload.uuid;
            attachment.title = file.name;
            attachment.type = "file";
            attachment.extension = "." + file.type.split("/")[1];
            attachment.user = JSON.parse(localStorage.getItem("user"));
            attachment.content = "File Upload by Select or Drop";
            attachment.thumb = file.dataURL;
            attachment.thumb_list = file.dataURL;
            attachment.isLoading = true;
            attachment.progress = null;
            attachment.size = file.size;
            this.tempAttachments = [...this.tempAttachments, attachment];
        },
        // a middle layer function where you can change the XHR request properties
        sendingFiles(file, xhr, formData) {
        },
        // function where we get the upload progress
        uploadProgress(file, progress, bytesSent) {
            this.tempAttachments.map(attachment => {
                if (attachment.title === file.name) {
                    attachment.progress = `${Math.floor(progress)}`;
                }
            });
        },
        // called on successful upload of a file
        success(file, response) {
            this.attachments.push(response);
            this.$emit("setAttachments", this.attachments);

            // remove tempAttachments
            const iTemp = this.tempAttachments.map((item) => item._id).indexOf(file.upload.uuid);

            if (iTemp >= 0) {
                this.tempAttachments.splice(iTemp, 1);
            }
        },
        removeFile(fileId) {
            // remove attachments
            const i = this.attachments.map((item) => item.id).indexOf(fileId);
            if (i >= 0) {
                this.totalSize -= this.attachments[i].size;
                this.attachments.splice(i, 1);
                let floatTotalSize = parseFloat(
                    (this.totalSize / (1024 * 1024)).toFixed(2)
                );
                if (floatTotalSize < 25) {
                    this.sizeExcedded = false;
                }
                this.$emit("setAttachments", this.attachments);
            } else {
                // remove tempAttachments
                const iTemp = this.tempAttachments.map((item) => item.id).indexOf(fileId);
                if (iTemp >= 0) {
                    this.totalSize -= this.tempAttachments[iTemp].size;
                    this.tempAttachments.splice(iTemp, 1);
                    let floatTotalSize = parseFloat(
                        (this.totalSize / (1024 * 1024)).toFixed(2)
                    );
                    if (floatTotalSize < 25) {
                        this.sizeExcedded = false;
                    }
                }
            }
        },
    },
    computed: {
        getAttachments() {
            return this.attachments;
        },
        getTempAttachments() {
            return this.tempAttachments;
        },
    },
};
</script>

<style>
div .ql-container.ql-snow,
div .ql-toolbar.ql-snow {
    border: transparent;
}

div .ql-toolbar.ql-snow {
    border-bottom: 1px solid;
    border-bottom-color: rgb(235, 237, 243) !important;
}

.dropzone.dz-clickable {
    border: transparent;
    border-top: 1px dashed rgb(235, 237, 243);
}
</style>
