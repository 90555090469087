<template>
    <div>
        <div
            class="dropzone dropzone-multi px-8 py-4 min-height-auto"
            id="kt_inbox_reply_attachments"
        >
            <div class="dropzone-items">
                <div
                    class="dropzone-item dz-processing dz-image-preview dz-error dz-complete"
                    style=""
                    v-for="attachment in tempAttachments"
                    :key="attachment.id"
                >
                    <div class="dropzone-file">
                        <div class="dropzone-filename" title="some_image_file_name.jpg">
                            <span data-dz-name="">{{ attachment.title }}</span>
                            <strong>
                                (
                                <span data-dz-size=""
                                    ><strong>{{ attachment.size }}</strong> bytes</span
                                >)</strong
                            >
                        </div>
                    </div>
                    <div class="dropzone-progress">
                        <div class="progress progress-xs bg-success-o-60">
                            <div
                                class="progress-bar bg-success"
                                role="progressbar"
                                :style="{
                                    width: `${attachment.progress}%`,
                                }"
                                aria-valuemin="0"
                                aria-valuemax="100"
                            ></div>
                        </div>
                    </div>
                    <div class="dropzone-toolbar">
                        <span class="dropzone-delete">
                            <i class="flaticon-upload-1"></i>
                        </span>
                    </div>
                    <div
                        class="dropzone-toolbar"
                        @click="removeFile(attachment.id)"
                        v-if="showRemove"
                    >
                        <span class="dropzone-delete" data-dz-remove="">
                            <i class="flaticon2-cross"></i>
                        </span>
                    </div>
                </div>
                <div
                    class="dropzone-item dz-processing dz-image-preview dz-error dz-complete"
                    style=""
                    v-for="attachment in attachments"
                    :key="attachment.id"
                >
                    <div
                        v-if="attachment.mimeType.split('/')[1] == 'pdf'"
                        class="pdf-file pe-3 justify-content-center"
                    >
                        <v-dialog max-width="1000px">
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on"
                                    ><v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon
                                                color="success"
                                                dark
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                mdi-file-pdf
                                            </v-icon>
                                        </template>
                                        <span>Vezi fisier</span>
                                    </v-tooltip></span
                                >
                            </template>
                            <v-card>
                                <v-divider></v-divider>
                                <v-card-text>
                                    <iframe
                                        style="width: 100%; max-height: 90vh"
                                        class="h-600px"
                                        :src="attachment.fileUrl"
                                    ></iframe>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                    </div>
                    <div
                        v-if="attachment.mimeType.split('/')[0] == 'image'"
                        class="image-file h-40px pe-3"
                    >
                        <button @click="showSlodeshow(attachment.fileUrl)">
                            <img :src="attachment.fileUrl" style="max-height: 40px" />
                        </button>
                    </div>
                    <div class="dropzone-file">
                        <div class="dropzone-filename" title="some_image_file_name.jpg">
                            <span data-dz-name="">{{ attachment.fileName }}</span>
                            <strong
                                >(
                                <span data-dz-size=""
                                    ><strong>{{ attachment.size }}</strong> bytes</span
                                >)</strong
                            >
                        </div>
                    </div>
                    <div
                        class="dropzone-toolbar"
                        @click="removeFile(attachment.id)"
                        v-if="showRemove"
                    >
                        <span class="dropzone-delete" data-dz-remove="">
                            <i class="flaticon2-cross"></i>
                        </span>
                    </div>
                    <div v-else>
                        <a
                            :href="attachment.fileUrl"
                            @click.prevent="downloadItem(attachment)"
                            class="text-info hover-info"
                        >
                            <span class="text-info">
                                <i
                                    class="flaticon2-download-2 text-info text-hover-success"
                                ></i>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <vue-easy-lightbox
            escDisabled
            moveDisabled
            :visible="visible"
            :imgs="imgs"
            :index="index"
            @hide="handleHide"
        ></vue-easy-lightbox>
    </div>
</template>

<script>
// import Image from "../../vue-bootstrap/Image.vue";
import VueEasyLightbox from "vue-easy-lightbox";
export default {
    components: { VueEasyLightbox },
    name: "AttachmentList",
    methods: {
        checkProgress(attachment) {
            return attachment.progress === null ? false : true;
        },
        removeFile(fileId) {
            this.$emit("removeFile", fileId);
        },
        downloadItem(item) {
            fetch(item.fileUrl)
                .then((response) => response.blob())
                .then((blob) => {
                    const link = document.createElement("a");
                    link.href = URL.createObjectURL(blob);
                    link.download = item.fileName;
                    link.click();
                    URL.revokeObjectURL(link.href);
                });
        },
        showSlodeshow(img) {
            let imgs = [];
            this.attachments.forEach((element, index) => {
                imgs.push(element.fileUrl);
                if (img == element.fileUrl) {
                    this.index = index;
                }
            });
            this.imgs = imgs;
            this.visible = true;
        },
        handleHide() {
            this.visible = false;
        },
    },
    props: {
        showRemove: {
            type: Boolean,
        },
        attachments: {
            type: Array,
        },
        tempAttachments: {
            type: Array,
        },
    },
    data() {
        return {
            imgs: "", // Img Url , string or Array of string
            visible: false,
            index: 0, // default: 0
        };
    },
};
</script>

<style>
.image-file img {
    max-width: 100%;
    max-height: 100%;
}
.file-name {
    min-width: 150px;
    font-size: 13px;
}
.uploaded-date {
    font-size: 12px;
}
.upload-progress {
    font-size: 12px;
}
.file-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}
.min-height-auto {
    min-height: auto !important;
}
</style>
