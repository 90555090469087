<template>
    <b-modal
        id="put-pending-modal"
        title="Selecteaza data si metoda de punere in asteptare."
        size="md"
    >
        <b-form id="put-pending-form" @submit="onSubmit">
            <b-form-group>
                <b-form-group label="Data:" label-for="datepicker-put-pending">
                    <b-form-datepicker
                        id="datepicker-put-pending"
                        v-model="form.date"
                        :min="min"
                        class="mb-2"
                        locale="ro"
                    ></b-form-datepicker>
                </b-form-group>
                <b-form-group label="Metoda de punere in asteptare: ">
                    <div
                        v-for="(column, i) in columns"
                        :key="i"
                        class="radio-row"
                        :class="{
                            'w-100': columns.length - 1 === i,
                            'col-6': columns.length - 1 < i,
                        }"
                    >
                        <div v-for="config in column" :key="config.id">
                            <b-form-radio
                                v-model="form.selected"
                                name="some-radios"
                                :value="config.status"
                                @change="selectedStatus()"
                                :class="{
                                    'w-100': config.status === 'custom',
                                }"
                            >
                                {{ config.name }}
                            </b-form-radio>
                            <b-form-input
                                v-if="config.status === 'custom'"
                                :disabled="form.selected !== 'custom'"
                                v-model="form.pending_custom"
                                placeholder="Introdu status personalizat"
                                :state="nameState"
                                required
                                class="w-75"
                            ></b-form-input>
                            <b-form-invalid-feedback id="custom-pending">
                                Introduceti cel putin 4 carcatere.
                            </b-form-invalid-feedback>
                        </div>
                    </div>
                </b-form-group>
            </b-form-group>
            <!-- <div class="mt-3">
        Selected: <strong>{{ form }}</strong>
      </div> -->
        </b-form>
        <template #modal-footer>
            <b-button type="submit" form="put-pending-form" size="sm" class="btn-pending">
                PUNE IN ASTEPTARE
            </b-button>
        </template>
    </b-modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    name: "ConvModal",
    data() {
        const now = new Date();
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        // const tommorow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
        // 15th two months prior
        let tomorrow = new Date();
        tomorrow.setDate(today.getDate() + 1);
        const minDate = new Date(today);
        minDate.setDate(minDate.getDate());

        return {
            date: new Date().toJSON().slice(0, 10),
            form: {
                date: new Date(tomorrow).toJSON().slice(0, 10),
                selected: "",
                pending_custom: "",
            },
            min: minDate,
        };
    },
    methods: {
        onSubmit(event) {
            event.preventDefault();
            let payload = {
                conversations_ids: this.conversation.id,
                status: "pending",
                pending_status: this.form.selected,
                pending_date: this.form.date,
            };

            if (this.form.selected === "custom") {
                payload.pending_custom = this.form.pending_custom;
            }

            this.$store.dispatch("buttons/updateStatus", payload).then((response) => {
                if (response) {
                    this.$bvModal.hide("put-pending-modal");
                    this.$store.dispatch("mails/getConversation", this.conversation.id);
                    this.$emit("assignee", true);
                    this.onReset();
                }
            });
        },
        onReset() {
            // Reset our form values
            this.form.date = new Date().toJSON().slice(0, 10);
            this.form.selected = "";
            this.form.pending_custom = "";
        },
        selectedStatus() {
            let selectedS = this.form.selected;
            this.$store.dispatch("buttons/getStatusModal", selectedS);
        },
        isValid() {
            return this.form.pending_custom.length > 3 ? true : false; //your validation criteria goes here
        },
    },
    computed: {
        ...mapGetters("mails", ["conversation"]),
        ...mapGetters("buttons", ["configs"]),
        ...mapGetters("buttons", ["columns"]),
        ...mapActions("buttons", ["getConfigs"]),
        nameState() {
            if (this.form.pending_custom) {
                return this.isValid();
            }
            return null;
        },
    },
    created() {
        this.getConfigs;
    },
};
</script>
