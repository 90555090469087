<template>
    <KTCodePreview
        v-bind:title="'Agent: ' + agentName"
        class="conversation-buttons-wrapper"
    >
        <template v-slot:title>
            <div
                class="symbol symbol-35 symbol-light mr-1 flex-shrink-0"
                v-b-tooltip.hover.top
                :title="conversation.departament"
            >
                <div class="symbol-label">
                    <span class="svg-icon svg-icon-2x svg-icon-primary">
                        <inline-svg :src="getSVG(conversation.departament)" />
                    </span>
                </div>
            </div>
            <h3 class="card-label">
                <span class="text-muted font-weight-bold">Agent: </span> {{ agentName }}
            </h3>
        </template>
        <!-- <template v-slot:buttons> <b-button>test</b-button> </template> -->
        <template v-slot:preview v-if="conversation.statusName">
            <div>
                <h3 class="d-flex align-items-center">
                    Stare: {{ conversation.statusName }}

                    <LabelButton
                        v-if="conversation.threadMessage"
                        :message="conversation.threadMessage"
                        :id="conversation.id"
                        :status="conversation.status"
                        class="ml-1 mr-1"
                    />
                    <LabelButton
                        v-if="conversation.pendingStatus"
                        :message="conversation.pendingStatus"
                        :id="conversation.id"
                        :status="conversation.status"
                        class="ml-1 mr-1"
                    />
                </h3>
            </div>
            <div>
                <process-buttons
                    v-if="conversation.status === 'open'"
                    :conversation="conversation"
                    @mailaction="mailaction"
                ></process-buttons>
                <b-button
                    @click="processAsignee()"
                    class="mr-3 process-button"
                    v-if="
                        conversation.status === 'active' ||
                        conversation.status === 'pending'
                    "
                    >Proceseaza</b-button
                >
                <b-button
                    @click="processAsignee()"
                    class="mr-3 process-button"
                    v-if="conversation.status === 'closed'"
                    >Reproceseaza</b-button
                >
            </div>
            <div class="col-lg-12 col-md-12 conv-btn-pass">
                <div class="conversation-pass">
                    <v-form ref="pasareForm" v-model="valid" lazy-validation>
                        <v-row v-if="conversation.status !== 'closed'">
                            <v-col cols="12"
                                ><span class="pb-2 pt-2 font-weight-bolder text-dark"
                                    >Asigneaza ticket:</span
                                ></v-col
                            >
                            <v-col cols="12" sm="4">
                                <v-select
                                    v-model="passDepartId"
                                    :items="eachDepartments"
                                    @change="getPasareDeparts()"
                                    :rules="[(v) => !!v || 'Campul este obligatoriu']"
                                    dense
                                    outlined
                                    required
                                ></v-select>
                            </v-col>
                            <v-col v-if="showSelectUser" cols="12" sm="4">
                                <!-- <b-form-select
                  v-if="showSelectUser"
                  class="conv-drd2"
                  v-model="code1.selected2"
                  :options="departmentUsers"
                ></b-form-select> -->
                                <v-select
                                    :disabled="!showSelectUser"
                                    v-model="passUserId"
                                    :items="departmentUsers"
                                    @change="getDepartUsers()"
                                    menu-props="auto"
                                    dense
                                    outlined
                                >
                                    <template v-slot:item="{ active, item, attrs, on }">
                                        <v-list-item
                                            v-on="on"
                                            v-bind="attrs"
                                            #default="{ active }"
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    <v-row no-gutters align="center">
                                                        <span>{{ item.text }}</span>
                                                        <v-spacer></v-spacer>
                                                        <span
                                                            v-if="item.value"
                                                            class="label label-rounded label-light-success font-weight-bolder ml-1"
                                                            :class="[
                                                                item.status === 'active'
                                                                    ? 'label-light-success'
                                                                    : '',
                                                                item.status === 'vacation'
                                                                    ? 'label-light-danger'
                                                                    : '',
                                                                item.status === 'busy'
                                                                    ? 'label-light-warning'
                                                                    : '',
                                                            ]"
                                                            >{{ item.count }}
                                                        </span>
                                                    </v-row>
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template></v-select
                                >
                            </v-col>
                            <v-col cols="12" sm="4">
                                <div>
                                    <v-btn
                                        type="submit"
                                        @click="mailAssignee"
                                        class="spam-btn btn-paseaza"
                                        >PASEAZA</v-btn
                                    >
                                </div>
                            </v-col>
                        </v-row>
                    </v-form>
                </div>
            </div>
        </template>
        <template v-slot:buttons>
            <!-- <div class="d-flex align-items-center"> -->
            <!-- <h5> -->
            <ActionsHistory :conversation="conversation" />

            <!-- </h5> -->
            <!-- </div> -->
        </template>
    </KTCodePreview>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import KTCodePreview from "@/view/content/CodePreview.vue";
import ProcessButtons from "@/view/pages/conversation/ProcessButtons.vue";
import ActionsHistory from "@/view/pages/conversation/ActionsHistory.vue";
import LabelButton from "@/view/content/buttons/LabelButton";

export default {
    data() {
        return {
            valid: true,
            showSelectUser: false,
            selected: this.$route.params.id,
            passDepartId: null,
            passUserId: null,
            code1: {
                selected1: null,
                selected2: null,
                options: [{ value: null, text: "Alege un departament*", disabled: true }],
                options2: [
                    { value: null, text: "Niciun agent selectat", disabled: true },
                ],
            },
        };
    },
    components: {
        KTCodePreview,
        ProcessButtons,
        LabelButton,
        ActionsHistory,
    },
    methods: {
        getSVG(departament) {
            return this.layoutConfig("departaments." + departament);
        },

        ...mapActions("mails", ["assigneeMail"]),
        mailAssignee(evt) {
            evt.preventDefault();

            if (this.$refs.pasareForm.validate()) {
                this.$store.commit("mails/setResetSelected");
                this.$store.commit("mails/selectMail", this.conversation.id);
                var payload = {
                    departId: this.passDepartId,
                    userId: this.passUserId,
                    number: this.conversation.number,
                };
                this.$store.dispatch("mails/assigneeMail", payload).then(() => {
                    // redirect spre dashboard daca asigneaza pe departamente care nu are acces
                    let depart = this.departments.find(
                        (dep) => dep.id === this.passDepartId
                    );
                    // nu e departamentul lui face redirect sau  nu e conversatia lui si nu e manager pe departament face redirect
                    if (
                        !depart ||
                        (this.passUserId !== this.user.id &&
                            !depart.isManager &&
                            this.conversation.status !== "active")
                    ) {
                        this.$router.push({
                            name: "user",
                            params: { user: this.user.id },
                        });
                    }

                    // nu e conversatia lui si nu e manager pe departament face redicer
                    // else if (this.passUserId !== this.user.id && !depart.isManager) {
                    //   this.$router.push({ name: "user", params: { user: this.user.id } });
                    // }

                    this.passDepartId = null;
                    this.passUserId = null;
                });
                this.$refs.pasareForm.resetValidation();
                this.$emit("assignee", true);
            }
        },
        mailSpam() {
            this.$store.commit("mails/setResetSelected");
            this.$store.commit("mails/selectMail", this.conversation.id);
            this.$store.dispatch("mails/spamMail");
            this.$router.push("/");
        },
        processAsignee() {
            const selectedMail = this.$route.params.id;
            const currentUser = this.user.id;
            this.$store.dispatch("mails/assigneeProcessMail", {
                conversations_ids: selectedMail,
                user_id: currentUser,
            });
            this.$emit("assignee", true);
            // this.$router.push("/dashboard");
        },
        getPasareDeparts() {
            let departId = this.passDepartId;
            let userId = null;
            if (departId) {
                this.showSelectUser = true;
            }
            this.$store.dispatch("mails/asigneeDepUser", { departId, userId });
            this.$store.dispatch("departments/getPasareUsers", departId);
        },
        getDepartUsers() {
            let departId = this.passDepartId;
            if (departId) {
                this.showSelectUser = true;
            }
            let userId = this.passUserId;
            this.$store.dispatch("mails/asigneeDepUser", { departId, userId });
        },
        mailaction() {
            this.$emit("assignee", true);
        },
    },
    created() {
        this.$store.dispatch("departments/getPasareDepartments");
    },
    computed: {
        btnStates() {
            return this.buttons.map((btn) => btn.state);
        },
        agentName() {
            if (this.conversation.assignee) {
                return (
                    this.conversation.assignee.firstName +
                    " " +
                    this.conversation.assignee.lastName
                );
            } else {
                return "Neasignat";
            }
        },
        ...mapActions("mails", ["assigneeMail"]),
        ...mapGetters("departments", ["eachDepartments", "departments"]),
        ...mapGetters("departments", ["departmentUsers"]),
        ...mapGetters("mails", ["conversation"]),
        ...mapGetters(["user"]),
        ...mapGetters(["layoutConfig"]),
    },
};
</script>

<style>
.btn.process-button {
    background-color: #67809f;
    color: white;
}

.btn-paseaza {
    background-color: #44b6ae !important;
    color: white !important;
}
</style>
